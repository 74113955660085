/* eslint-disable jsx-a11y/anchor-is-valid */
import {Outlet} from 'react-router-dom'

const AuthLayout = () => {
  return (
    <div className='d-flex flex-column flex-lg-row flex-grow-1 h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-grow-1 p-2 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex align-items-center justify-content-center flex-column flex-grow-1'>
          {/* begin::Wrapper */}
          <div className='d-flex flex-column flex-between pb-0 pb-lg-10 p-10 w-100'>
            
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {AuthLayout}
