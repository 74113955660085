import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getProfileById,
} from "../core/_requests";
import { ProfilesForm } from "../profiles-form/ProfilesForm";

const ProfilesEdit = () => {
  const { profileId } = useParams();
  
  const {
    isLoading,
    data: profile,
    error,
  } = useQuery(
    `PROFILE-${profileId}`,
    () => {
      if(profileId) {
        return getProfileById(profileId!)
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <>
      {
        profileId
        ?
        !isLoading && !error && profile && (
          <ProfilesForm profile={profile} />
        )
        :
        <ProfilesForm profile={{id: undefined}} />
      }
    </>
  );
};

export { ProfilesEdit };
