import axios, { AxiosResponse } from "axios"
import { Client, ResponseClient } from "./_models"

const API_URL = process.env.REACT_APP_API_URL
const CLIENT_URL = `${API_URL}/clients`

const getClients = (): Promise<Array<Client>> => {
    return axios.get(CLIENT_URL).then((d: AxiosResponse<Array<Client>>) => d.data)
}

const getClientById = (id: string): Promise<Client> => {
  return axios
    .get(`${CLIENT_URL}/${id}`)
    .then((response: AxiosResponse<Client>) => response.data)
}

const createClient = (client: Client): Promise<ResponseClient> => {
    return axios
      .post(`${CLIENT_URL}`, client)
      .then((response: AxiosResponse<ResponseClient>) => response.data)
}

const updateClient = (client: Client): Promise<ResponseClient> => {
    return axios
      .post(`${CLIENT_URL}/${client.id}/update`, client)
      .then((response: AxiosResponse<ResponseClient>) => response.data)
}

const deleteClient = (clientId: number): Promise<ResponseClient> => {
  return axios.delete(`${CLIENT_URL}/${clientId}/delete`)
  .then((response: AxiosResponse<ResponseClient>) => response.data)
}

export {getClients, getClientById, createClient, updateClient, deleteClient}
