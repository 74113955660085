import axios, { AxiosResponse } from "axios"
import { Profile, ResponseProfile, Module } from "./_models"

const API_URL = process.env.REACT_APP_API_URL
const PROFILE_URL = `${API_URL}/profiles`
const MODULE_URL = `${API_URL}/modules`

const getProfiles = (): Promise<Array<Profile>> => {
    return axios.get(PROFILE_URL).then((d: AxiosResponse<Array<Profile>>) => d.data)
}

const getProfileById = (id: string): Promise<Profile> => {
  return axios
    .get(`${PROFILE_URL}/${id}`)
    .then((response: AxiosResponse<Profile>) => response.data)
}

const createProfile = (profile: Profile): Promise<ResponseProfile> => {
    return axios
      .post(`${PROFILE_URL}`, profile)
      .then((response: AxiosResponse<ResponseProfile>) => response.data)
}

const updateProfile = (profile: Profile): Promise<ResponseProfile> => {
    return axios
      .post(`${PROFILE_URL}/${profile.id}/update`, profile)
      .then((response: AxiosResponse<ResponseProfile>) => response.data)
}

const deleteProfile = (profileId: number): Promise<ResponseProfile> => {
  return axios.delete(`${PROFILE_URL}/${profileId}/delete`)
  .then((response: AxiosResponse<ResponseProfile>) => response.data)
}

const getModules = (): Promise<Array<Module>> => {
  return axios.get(MODULE_URL).then((d: AxiosResponse<Array<Module>>) => d.data)
}

export {getProfiles, getProfileById, createProfile, updateProfile, deleteProfile, getModules}
