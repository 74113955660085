import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getAreaById,
} from "../core/_requests";
import { AreasForm } from "../areas-form/AreasForm";

const AreasEdit = () => {
  const { areaId } = useParams();
  
  const {
    isLoading,
    data: area,
    error,
  } = useQuery(
    `AREA-${areaId}`,
    () => {
      if(areaId) {
        return getAreaById(areaId!)
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <>
      {
        areaId
        ?
        !isLoading && !error && area && (
          <AreasForm area={area} />
        )
        :
        <AreasForm area={{id: undefined}} />
      }
    </>
  );
};

export { AreasEdit };
