export type Bearing = {
    id?: number,
    manufacturer?: string,
    model?: string,
    bpfi?: string
    bpfo?: string
    bsf?: string
    fft?: string
    type?: string
}

export type ResponseBearing = {
    message: string
}

export const BearingInitialValue: Bearing = {
    id: 0,
    manufacturer: '',
    model: '',
    bpfi: '',
    bpfo: '',
    bsf: '',
    fft: '',
    type: ''
}