import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getAlarmParameterizationById } from "../core/_requests";
import { AlarmParameterizationsForm } from "../alarm-parameterizations-form/AlarmParameterizationsForm";

const AlarmParameterizationsEdit = () => {
  const { parameterizationId } = useParams();

  const {
    isLoading,
    data: parameterization,
    error,
  } = useQuery(
    `ALARM_PARAMETERIZATIONS-${parameterizationId}`,
    () => {
      if (parameterizationId) {
        return getAlarmParameterizationById(parameterizationId!);
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  return (
    <>
      {parameterizationId ? (
        !isLoading &&
        !error &&
        parameterization && (
          <AlarmParameterizationsForm parameterization={parameterization} />
        )
      ) : (
        <AlarmParameterizationsForm parameterization={{ id: undefined }} />
      )}
    </>
  );
};

export { AlarmParameterizationsEdit };
