import { useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getUsers, deleteUser } from "../core/_requests";
import { User } from "../core/_models";
import { AlertBoostrapButtons } from "../../../../../theme/widgets/alerts/AlertBoostrapButtons";
import { Toast } from "../../../../../theme/widgets/alerts/Toast";
import { useAuth } from "../../../../modules/auth";

const UsersList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  const userPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 1;
  })[0];

  const columns = [
    {
      name: "Nome",
      selector: (row: User) => row.name!,
      sortable: true,
    },
    {
      name: "E-mail",
      selector: (row: User) => row.email!,
      sortable: true,
    },
    {
      name: "Função",
      selector: (row: User) => row.function!,
    },
    {
      name: "Telefone",
      selector: (row: User) => row.phone!,
    },
    {
      name: "Ações",
      cell: (row: User) => (
        <>
          {(!!userPermissions?.update ||
            currentUser?.profile.name === "administrador") && (
            <Link
              to={`/users/${row.id}`}
              className="btn btn-success btn-sm me-2"
              title="Editar"
            >
              <i className="bi bi-pencil"></i>
            </Link>
          )}
          {(!!userPermissions?.delete ||
            currentUser?.profile.name === "administrador") && (
            <button
              onClick={() => confirmUserDelete(row.id!)}
              className="btn btn-danger btn-sm"
              title="Remover"
            >
              <i className="bi bi-trash"></i>
            </button>
          )}
        </>
      ),
      ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        border: "none !important",
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F9FAFC",
        },
        fontSize: "14px",
        color: "#212529",
      },
    },

    headRow: {
      style: {
        backgroundColor: "#F9FAFC",
        fontWeight: "bold",
        border: "0px",
        fontSize: "15px",
        color: "#212529",
      },
    },
  };

  const { isFetching, refetch, data } = useQuery(
    `USERS_LIST`,
    () => {
      return getUsers();
    },
    {
      onSuccess: (data) => {
        setUsers(data);
      },
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const confirmUserDelete = async (userId: number) => {
    AlertBoostrapButtons.fire({
      title: "Você tem certeza que deseja remover esse usuário?",
      html: `Essa ação não poderá ser desfeita`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Excluir",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        return await deleteItem.mutateAsync(userId);
      }
    });
  };

  const deleteItem = useMutation((id: number) => deleteUser(id), {
    onSuccess: (response) => {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          popup: "bg-success",
          title: "text-light",
        },
      });
      queryClient.invalidateQueries(["USERS_LIST"]);
    },
  });

  const filteredUsers = users.filter(
    (user) =>
      (user?.name ?? "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user?.email ?? "").toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  return (
    <>
      <div className="row justify-content-between align-items-center border border-color-ccc shadow-sm rounded bg-white col-12 mx-auto mt-3 py-4 col-lg-11">
        <div className="col-12 d-flex justify-content-between gap-2 flex-column flex-lg-row align-items-lg-center ">
          <div className="col-4">
            <h2 className="mb-0 font-weight-bold">Usuários</h2>
          </div>

          <div className="d-flex justify-content-between gap-3 flex-column flex-lg-row align-items-lg-center ">
            <div className="input-wrapper">
              <button className="icon">
                <i className="bi bi-search "></i>
              </button>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Pesquisar"
                className="search-input"
              />
            </div>

            {(!!userPermissions?.create ||
              currentUser?.profile.name === "administrador") && (
              <div className="p-0">
                <Link to={"/users/new"} className="btn btn-primary">
                  <i className="bi bi-plus-circle me-2"></i>
                  Novo usuário
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="row mt-3 m-0 p-0">
          <div className="col-12 m-0 p-0">
            {!isFetching ? (
              filteredUsers.length ? (
                <DataTable
                  columns={columns}
                  data={filteredUsers}
                  customStyles={customStyles}
                />
              ) : (
                <span className="p-3">Nenhum dado encontrado</span>
              )
            ) : (
              <span>Carregando...</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { UsersList };
