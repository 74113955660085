import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getUserById,
} from "../core/_requests";
import { UsersForm } from "../users-form/UsersForm";

const UsersEdit = () => {
  const { userId } = useParams();
  
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `USER-${userId}`,
    () => {
      if(userId) {
        return getUserById(userId!)
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <>
      {
        userId
        ?
        !isLoading && !error && user && (
          <UsersForm user={user} />
        )
        :
        <UsersForm user={{id: undefined}} />
      }
    </>
  );
};

export { UsersEdit };
