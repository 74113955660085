import { useRef, Fragment, useEffect } from "react";
import {
  DefaultPaletteProvider,
  CustomAnnotation,
  FastLineRenderableSeries,
  SciChartSurface,
  SciChartJsNavyTheme,
  NumericAxis,
  EAxisAlignment,
  NumberRange,
  ENumericFormat,
  EXyDirection,
  MouseWheelZoomModifier,
  ZoomExtentsModifier,
  IRenderableSeries,
  SeriesSelectionModifier,
  LegendModifier,
  XyDataSeries,
  RubberBandXyZoomModifier,
  WaveAnimation,
  CentralAxesLayoutManager,
  ICentralAxesLayoutManagerOptions,
  EInnerAxisPlacementCoordinateMode,
  RolloverModifier,
} from "scichart";
import { useSpectraResponseData } from "../core/SpectraResponseProvider";
import { useSpectraResponseLoading } from "../core/SpectraResponseProvider";
import { useSpectraRequest } from "../core/SpectraRequestProvider";
import { Loading } from "../../../../../theme/widgets/loading/Loading";

class CrossSectionPaletteProvider extends DefaultPaletteProvider {
  public selectedIndex: number = -1;
  public shouldUpdate: boolean = true;

  public shouldUpdatePalette(): boolean {
    return this.shouldUpdate;
  }
}

export const divMainChartId = "waveChart";
// export const divCrossSection1 = "sciChart2";
// export const divCrossSection2 = "sciChart3";

let mainChartSurface: SciChartSurface;
const crossSectionPaletteProvider = new CrossSectionPaletteProvider();
let mainChartSelectionModifier: SeriesSelectionModifier;

const WaveChart = () => {
  const { updateState } = useSpectraRequest();
  const data = useSpectraResponseData();
  const isLoading = useSpectraResponseLoading();
  const ref = useRef<HTMLDivElement>(null);
  const series = ["axial", "horizontal", "vertical"];
  const seriesColors = ["#838485", "#04ba13", "#ff6600"];

  useEffect(() => {
    getSpectraData();
  }, []);

  const getSpectraData = () => {
    updateState({ filter: { date: "20" } });
  };

  const theme = new SciChartJsNavyTheme();
  theme.sciChartBackground = "#205a89";

  const createSpectralData = async (serie: string) => {
    //@ts-ignore
    const timeData: number[] = data[0].capture[serie];

    const sampleRate = 16000; // Taxa de amostragem em Hz
    const timeStep = 1 / sampleRate; // Passo de tempo entre as amostras

    // Cria um array de tempo com base na taxa de amostragem
    const time = timeData.map((_, i) => i * timeStep);

    const yValues = timeData;
    const xValues = time;

    return { xValues, yValues };
  };

  useEffect(() => {
    if (data.length) {
      initMainChart();
    }
  }, [data]);

  // This function creates the main chart with waterfall series
  // To do this, we create N series, each with its own X,Y axis with a different X,Y offset
  // all axis other than the first are hidden
  const initMainChart = async () => {
    const { sciChartSurface, wasmContext } = await SciChartSurface.create(
      divMainChartId,
      {
        disableAspect: true,
        theme,
      }
    );

    mainChartSurface = sciChartSurface;

    var seriesCount = series.length;

    for (let i = 0; i < seriesCount; i++) {
      // Create some data for the example
      const { xValues, yValues } = await createSpectralData(series[i]);

      // Create one yAxis per series
      const yAxis = new NumericAxis(wasmContext, {
        id: "Y" + i,
        axisAlignment: EAxisAlignment.Left,
        maxAutoTicks: 5,
        drawMinorGridLines: false,
        visibleRange: new NumberRange(Math.min(...yValues), Math.max(...yValues)),
        isVisible: i === seriesCount - 1,
        overrideOffset: 3 * -i,
      });
      sciChartSurface.yAxes.add(yAxis);

      // Create a shared, default xaxis
      const xAxis = new NumericAxis(wasmContext, {
        id: "X" + i,
        labelFormat: ENumericFormat.SignificantFigures,
        axisTitleStyle: { fontSize: 10 },
        growBy: new NumberRange(0.05, 0.2),
        isVisible: i === seriesCount - 1,
        overrideOffset: 2 * i,
      });
      sciChartSurface.xAxes.add(xAxis);

      const options: ICentralAxesLayoutManagerOptions = {
        horizontalAxisPositionCoordinateMode:
          EInnerAxisPlacementCoordinateMode.Relative,
        verticalAxisPositionCoordinateMode:
          EInnerAxisPlacementCoordinateMode.Relative,
        horizontalAxisPosition: 1,
        verticalAxisPosition: 0,
      };

      sciChartSurface.layoutManager = new CentralAxesLayoutManager(options);

      mainChartSurface.rendered.subscribe(() => {
        // Don't recalculate the palette unless the selected index changes
        crossSectionPaletteProvider.shouldUpdate = false;
      });
      const lineSeries = new FastLineRenderableSeries(wasmContext, {
        id: "waveS" + i,
        xAxisId: "X" + i,
        yAxisId: "Y" + i,
        stroke: seriesColors[i],
        strokeThickness: 3,
        dataSeries: new XyDataSeries(wasmContext, {
          xValues,
          yValues,
          dataSeriesName: series[i].replace(/^./, series[i][0].toUpperCase()),
        }),
        // paletteProvider: crossSectionPaletteProvider,
      });
      // Insert series in reverse order so the ones at the bottom of the chart are drawn first
      // sciChartSurface.renderableSeries.insert(0, lineSeries);
      sciChartSurface.renderableSeries.add(lineSeries);

      const waveAnimation = new WaveAnimation({
        zeroLine: 0,
        pointDurationFraction: 0.5,
        duration: 2000,
        fadeEffect: true,
        delay: 1000,
      });
      lineSeries.enqueueAnimation(waveAnimation);
    }

    sciChartSurface.chartModifiers.add(
      new LegendModifier({ showCheckboxes: true })
    );

    // Add a tooltip behavior using the RolloverModifier
    // @ts-ignore
    const tooltipModifier = new RolloverModifier(wasmContext);
    sciChartSurface.chartModifiers.add(tooltipModifier);

    // Add zooming behaviours
    sciChartSurface.chartModifiers.add(
      // new ZoomPanModifier({ xyDirection: EXyDirection.XDirection }),
      new RubberBandXyZoomModifier({ xyDirection: EXyDirection.XDirection }),
      new MouseWheelZoomModifier({ xyDirection: EXyDirection.XDirection }),
      new ZoomExtentsModifier({ xyDirection: EXyDirection.XDirection })
    );

    let prevSelectedSeries: IRenderableSeries =
      sciChartSurface.renderableSeries.get(0);
    // Add selection behaviour
    mainChartSelectionModifier = new SeriesSelectionModifier({
      enableHover: true,
      enableSelection: true,
      hitTestRadius: 5,
    });
    sciChartSurface.chartModifiers.add(mainChartSelectionModifier);
    return { sciChartSurface };
  };

  return (
    <div className="col-12 pb-5">
      <div className="row justify-content-between mb-4">
        <div className="col-4">
          <h3 className="mb-0 font-weight-bold">Forma de onda</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {data.length ? (
            <div
              ref={ref}
              style={{
                width: "100%",
                height: "300px",
                display: "flex",
              }}
            >
              <div id={divMainChartId} style={{ flex: 1, flexBasis: "100%" }} />
            </div>
          ) : (
            <h5>Nenhum dado encontrado para este equipamento</h5>
          )}
        </div>
      </div>
      {isLoading && <Loading />}
    </div>
  );
};

export { WaveChart };
