/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { useAuth } from "../core/Auth";

const initialValues = {
  email: "",
  password: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  // 'Wrong email format'
  const loginSchema = Yup.object().shape({
    email: Yup.string().required("Campo obrigatório"),
    password: Yup.string().required("Campo obrigatório"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await login(values.email, values.password);
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth.api_token);
        setCurrentUser(user);
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus("Os detalhes de login estão incorretos");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="d-flex flex-wrap flex-column flex-lg-row justify-content-center">
        {/*begin::Wrapper*/}
        <div className="d-flex flex-grow-1 align-items-center justify-content-center mb-3 mb-lg-0">
          <div className="d-flex flex-column flex-center">
            <img src="/logo_acoplast.png" alt="Acoplast" className="w-md-300px"/>
          </div>
        </div>
        <div className="d-flex flex-grow-1 justify-content-center">
          <div className="d-flex flex-column flex-center rounded-4 p-4 border w-100 w-md-75 w-lg-450px">
            {/*begin::Form*/}
            <form
              className="form w-100"
              onSubmit={formik.handleSubmit}
              noValidate
              id="kt_login_signin_form"
            >
              {/*begin::Heading*/}
              <div className="text-center mb-5">
                {/*begin::Title*/}
                <h1 className="text-dark fw-bolder mb-3">Login</h1>
                {/*end::Title*/}
              </div>
              {/*begin::Heading*/}
              {formik.status && (
                <div className="alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              )}
              {/* begin::Form group */}
              {/* <div className="fv-row mb-3">
                <label className='form-label fs-6 text-dark'>
                  E-mail
                </label>
                <input
                  placeholder="E-mail"
                  {...formik.getFieldProps("email")}
                  className={clsx(
                    "form-control bg-transparent",
                    { "is-invalid": formik.touched.email && formik.errors.email },
                    {
                      "is-valid": formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type="email"
                  name="email"
                  autoComplete="off"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="fv-plugins-message-container invalid-feedback">
                    <span role="alert">{formik.errors.email}</span>
                  </div>
                )}
              </div> */}

              <div className="fv-row mb-3">
                <label className='form-label fs-6 text-dark'>
                  E-mail ou Usuário
                </label>
                <input
                  placeholder="E-mail ou Usuário"
                  {...formik.getFieldProps("email")}
                  className={clsx(
                    "form-control bg-transparent",
                    { "is-invalid": formik.touched.email && formik.errors.email },
                    { "is-valid": formik.touched.email && !formik.errors.email }
                  )}
                  type="text"
                  name="email"
                  autoComplete="off"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="fv-plugins-message-container invalid-feedback">
                    <span role="alert">{formik.errors.email}</span>
                  </div>
                )}
              </div> 
              
              {/* end::Form group */}

              <div className="fv-row mb-1">
                <label className='form-label text-dark fs-6 mb-0'>
                  Senha
                </label>
                <input
                  {...formik.getFieldProps("password")}
                  placeholder="Senha"
                  type="password"
                  name="password"
                  autoComplete="off"
                  className={clsx(
                    "form-control bg-transparent",
                    {
                      "is-invalid":
                        formik.touched.password && formik.errors.password,
                    },
                    {
                      "is-valid":
                        formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>

              {/*begin::Wrapper*/}
              <div className="d-flex flex-stack flex-wrap fs-7 mb-3 justify-content-end">
                {/* begin::Link */}
                <Link to="/auth/forgot-password" className="link-primary">
                  Esqueci minha senha
                </Link>
                {/* end::Link */}
              </div>
              {/*end::Wrapper*/}

              {/*begin::Submit button*/}
              <div className="d-grid">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="btn btn-primary"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className="indicator-label">Entrar</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Carregando
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
              {/*end::Submit button*/}
            </form>
          </div>
        </div>
        {/*end::Wrapper*/}
      </div>
    </>
  );
}
