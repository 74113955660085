import axios, { AxiosResponse } from "axios"
import { Bearing, ResponseBearing } from "./_models"

const API_URL = process.env.REACT_APP_API_URL
const BEARING_URL = `${API_URL}/bearings`

const getBearings = (query: string = ''): Promise<Array<Bearing>> => {
    return axios.get(`${BEARING_URL}?${query}`).then((d: AxiosResponse<Array<Bearing>>) => d.data)
}

const getBearingById = (id: string): Promise<Bearing> => {
  return axios
    .get(`${BEARING_URL}/${id}`)
    .then((response: AxiosResponse<Bearing>) => response.data)
}

const createBearing = (bearing: Bearing): Promise<ResponseBearing> => {
    return axios
      .post(`${BEARING_URL}`, bearing)
      .then((response: AxiosResponse<ResponseBearing>) => response.data)
}

const updateBearing = (bearing: Bearing): Promise<ResponseBearing> => {
    return axios
      .put(`${BEARING_URL}/${bearing.id}`, bearing)
      .then((response: AxiosResponse<ResponseBearing>) => response.data)
}

const deleteBearing = (bearingId: number): Promise<ResponseBearing> => {
  return axios.delete(`${BEARING_URL}/${bearingId}`)
  .then((response: AxiosResponse<ResponseBearing>) => response.data)
}

export {getBearings, getBearingById, createBearing, updateBearing, deleteBearing}
