import { FC } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive } from "../../../../helpers/RouterHelpers";
import { WithChildren } from "../../../../helpers/Model";
import { useLayoutContext } from "../../../MasterLayout";

type Props = {
  to: string;
  title: string;
  icon?: string;
  hasBullet?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);

  const { open, setOpen, isMobile } = useLayoutContext();


  const handleClick = () => {
    if (open && isMobile) {
      setOpen(false);
    }
  };

  return (
    <div onClick={handleClick}>
      <div className="nav-item">
        <Link className={clsx("nav-link", { active: isActive })} to={to}>
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {icon && (
            <i className={clsx("bi", icon)} title={open ? "" : title}></i>
          )}
          <span className={`${!open ? "d-none" : ""} menu-title ms-3`}>
            {title}
          </span>
        </Link>
        {children}
      </div>
    </div>
  );
};

export { SidebarMenuItem };
