import { useQuery } from "react-query";
import { getSettings } from "../core/_requests";
import { SettingsForm } from "../settings-form/SettingsForm";

const SettingsEdit = () => {
  const {
    isLoading,
    data: settings,
    error,
  } = useQuery(
    `GET-SETTINGS`,
    () => {
      return getSettings();
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  return (
    <>
      {!isLoading && !error && settings && <SettingsForm setting={settings} />}
    </>
  );
};

export { SettingsEdit };
