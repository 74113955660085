import { Routes, Outlet, Route } from "react-router-dom";
import { AlarmParameterizationsList } from "./components/alarm-parameterizations-list/AlarmParameterizationsList";
import { AlarmParameterizationsEdit } from "./components/alarm-parameterizations-edit/AlarmParameterizationsEdit";
import { useAuth } from "../../modules/auth";

const AlarmParameterizationsPage = () => {
  const { currentUser } = useAuth();
  const parameterizationPermissions = currentUser?.permissions?.filter(
    (permission) => {
      return permission.module_id === 8;
    }
  )[0];

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="/" element={<AlarmParameterizationsList />} />
        {(!!parameterizationPermissions?.create ||
          currentUser?.profile.name === "administrador") && (
          <Route path="/new" element={<AlarmParameterizationsEdit />} />
        )}
        {(!!parameterizationPermissions?.update ||
          currentUser?.profile.name === "administrador") && (
          <Route
            path=":parameterizationId"
            element={<AlarmParameterizationsEdit />}
          />
        )}
      </Route>
    </Routes>
  );
};

export default AlarmParameterizationsPage;
