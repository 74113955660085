import { Routes, Outlet, Route } from "react-router-dom";
import { ProfilesList } from "./components/profiles-list/ProfilesList";
import { ProfilesEdit } from "./components/profiles-edit/ProfilesEdit";
import { useAuth } from "../../modules/auth";

const ProfilesPage = () => {
  const { currentUser } = useAuth();
  const profilePermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 2;
  })[0];

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="/" element={<ProfilesList />} />
        {(!!profilePermissions?.create ||
          currentUser?.profile.name === "administrador") && (
          <Route path="/new" element={<ProfilesEdit />} />
        )}
        {(!!profilePermissions?.update ||
          currentUser?.profile.name === "administrador") && (
          <Route path=":profileId" element={<ProfilesEdit />} />
        )}
      </Route>
    </Routes>
  );
};

export default ProfilesPage;
