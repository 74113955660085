import axios, { AxiosResponse } from "axios"
import { User, ResponseUser } from "./_models"

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/users`

const getUsers = (): Promise<Array<User>> => {
    return axios.get(USER_URL).then((d: AxiosResponse<Array<User>>) => d.data)
}

const getUserById = (id: string): Promise<User> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<User>) => response.data)
}

const createUser = (user: User): Promise<ResponseUser> => {
    return axios
      .post(`${USER_URL}`, user)
      .then((response: AxiosResponse<ResponseUser>) => response.data)
}

const updateUser = (user: User): Promise<ResponseUser> => {
    return axios
      .put(`${USER_URL}/${user.id}`, user)
      .then((response: AxiosResponse<ResponseUser>) => response.data)
}

const deleteUser = (userId: number): Promise<ResponseUser> => {
  return axios.delete(`${USER_URL}/${userId}/delete`)
  .then((response: AxiosResponse<ResponseUser>) => response.data)
}

export {getUsers, getUserById, createUser, updateUser, deleteUser}
