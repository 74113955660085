import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getEquipmentById,
} from "../core/_requests";
import { EquipmentForm } from "../equipment-form/EquipmentForm";

const EquipmentEdit = () => {
  const { equipmentId } = useParams();
  
  const {
    isLoading,
    data: equipment,
    error,
  } = useQuery(
    `EQUIPMENT-${equipmentId}`,
    () => {
      if(equipmentId) {
        return getEquipmentById(equipmentId!)
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <>
      {
        equipmentId
        ?
        !isLoading && !error && equipment && (
          <EquipmentForm equipment={equipment} />
        )
        :
        <EquipmentForm equipment={{id: undefined}} />
      }
    </>
  );
};

export { EquipmentEdit };
