import axios, { AxiosResponse } from "axios"
import { Cell, ResponseCell } from "./_models"

const API_URL = process.env.REACT_APP_API_URL
const CELL_URL = `${API_URL}/cells`

const getCells = (query: string = ''): Promise<Array<Cell>> => {
    return axios.get(`${CELL_URL}?${query}`).then((d: AxiosResponse<Array<Cell>>) => d.data)
}

const getCellById = (id: string): Promise<Cell> => {
  return axios
    .get(`${CELL_URL}/${id}`)
    .then((response: AxiosResponse<Cell>) => response.data)
}

const createCell = (cell: Cell): Promise<ResponseCell> => {
    return axios
      .post(`${CELL_URL}`, cell)
      .then((response: AxiosResponse<ResponseCell>) => response.data)
}

const updateCell = (cell: Cell): Promise<ResponseCell> => {
    return axios
      .put(`${CELL_URL}/${cell.id}`, cell)
      .then((response: AxiosResponse<ResponseCell>) => response.data)
}

const deleteCell = (cellId: number): Promise<ResponseCell> => {
  return axios.delete(`${CELL_URL}/${cellId}`)
  .then((response: AxiosResponse<ResponseCell>) => response.data)
}

export {getCells, getCellById, createCell, updateCell, deleteCell}
