import React, { useState } from "react";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useAuth } from "../../../../../app/modules/auth";
import { useLayoutContext } from "../../../MasterLayout";

const SidebarMenuMain = () => {
  const { currentUser, logout } = useAuth();
  const userPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 1;
  })[0];
  const profilePermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 2;
  })[0];
  const clientPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 3;
  })[0];
  const areaPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 4;
  })[0];
  const cellPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 5;
  })[0];
  const equipmentPermissions = currentUser?.permissions?.filter(
    (permission) => {
      return permission.module_id === 6;
    }
  )[0];
  const bearingPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 7;
  })[0];
  const parameterizationPermissions = currentUser?.permissions?.filter(
    (permission) => {
      return permission.module_id === 8;
    }
  )[0];

  const handleLogout = () => {
    logout();
  };

  const [isCadastroMenuOpen, setIsCadastroMenuOpen] = useState(false);

  const handleCadastroMenuToggle = () => {
    setIsCadastroMenuOpen(!isCadastroMenuOpen);
  };

  const { open, setOpen } = useLayoutContext();

  const handleClick = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="sidebar-item-container d-flex flex-column gap-2">
        <SidebarMenuItem to="/dashboard" icon="bi-stack" title="Dashboard" />

        <div className="nav-item">
          <button
            className="position-relative nav-link sidebar-menu-item d-flex w-100"
            onClick={handleCadastroMenuToggle}
          >
            <i className="bi bi-file-earmark-plus"></i>
            <span className={`${!open ? "d-none" : ""} menu-title ms-3`}>
              {" "}
              Cadastros{" "}
            </span>

            <i
              className={`position-absolute end-0 me-3 ${
                isCadastroMenuOpen
                  ? "bi-chevron-right rotate-90 fw-bold transition-all-300 "
                  : "bi-chevron-right fw-bold transition-all-300 "
              } ${open && "me-5"}`}
            ></i>
          </button>
        </div>

        {isCadastroMenuOpen && (
          <div className="mx-auto">
            {(!!userPermissions?.read ||
              currentUser?.profile.name === "administrador") && (
              <SidebarMenuItem
                to="/users"
                icon="bi-people-fill"
                title="Usuários"
              />
            )}

            {(!!profilePermissions?.read ||
              currentUser?.profile.name === "administrador") && (
              <SidebarMenuItem
                to="/profiles"
                icon="bi-person-circle"
                title="Perfis"
              />
            )}

            {(!!clientPermissions?.read ||
              currentUser?.profile.name === "administrador") && (
              <SidebarMenuItem
                to="/clients"
                icon="bi-person-lines-fill"
                title="Clientes"
              />
            )}

            {(!!areaPermissions?.read ||
              currentUser?.profile.name === "administrador") && (
              <SidebarMenuItem
                to="/areas"
                icon="bi-collection-fill"
                title="Áreas"
              />
            )}

            {(!!cellPermissions?.read ||
              currentUser?.profile.name === "administrador") && (
              <SidebarMenuItem to="/cells" icon="bi-boxes" title="Células" />
            )}

            {(!!bearingPermissions?.read ||
              currentUser?.profile.name === "administrador") && (
              <SidebarMenuItem
                to="/bearings"
                icon="bi-gear-fill"
                title="Rolamentos"
              />
            )}

            {(!!equipmentPermissions?.read ||
              currentUser?.profile.name === "administrador") && (
              <SidebarMenuItem
                to="/equipment"
                icon="bi-wrench-adjustable-circle-fill"
                title="Equipamentos"
              />
            )}

            {(!!parameterizationPermissions?.read ||
              currentUser?.profile.name === "administrador") && (
              <SidebarMenuItem
                to="/alarm-parameterizations"
                icon="bi-exclamation-circle-fill"
                title="Alarmes"
              />
            )}
          </div>
        )}

        <SidebarMenuItem to="/settings" icon="bi-sliders" title="Configurações" />

        <div className="nav-item">
          <button className="nav-link sidebar-menu-item" onClick={handleLogout}>
            <i className="bi bi-box-arrow-left" title={!open ? "Sair" : ""}></i>
            <span className={`${!open ? "d-none" : ""} menu-title ms-3`}>
              Sair
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export { SidebarMenuMain };
