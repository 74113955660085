import axios, { AxiosResponse } from "axios"
import { Setting, ResponseSetting } from "./_models"

const API_URL = process.env.REACT_APP_API_URL
const SETTING_URL = `${API_URL}/settings`

const getSettings = (): Promise<Setting> => {
  return axios
    .get(`${SETTING_URL}`)
    .then((response: AxiosResponse<Setting>) => response.data)
}

const saveClientid = (settings: Setting): Promise<ResponseSetting> => {
    return axios
      .post(`${SETTING_URL}`, settings)
      .then((response: AxiosResponse<ResponseSetting>) => response.data)
}

export {getSettings, saveClientid}
