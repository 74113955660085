import { Routes, Outlet, Route } from "react-router-dom";
import { AreasList } from "./components/areas-list/AreasList";
import { AreasEdit } from "./components/areas-edit/AreasEdit";
import { useAuth } from "../../modules/auth";

const AreasPage = () => {
  const { currentUser } = useAuth();
  const areaPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 4;
  })[0];

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="/" element={<AreasList />} />
        {(!!areaPermissions?.create ||
          currentUser?.profile.name === "administrador") && (
          <Route path="/new" element={<AreasEdit />} />
        )}
        {(!!areaPermissions?.update ||
          currentUser?.profile.name === "administrador") && (
          <Route path=":areaId" element={<AreasEdit />} />
        )}
      </Route>
    </Routes>
  );
};

export default AreasPage;
