import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { TemperatureRequestProvider } from "../core/TemperatureRequestProvider";
import { AccelerationRequestProvider } from "../core/AccelerationRequestProvider";
import { VelocityRequestProvider } from "../core/VelocityRequestProvider";
import { VibrationRequestProvider } from "../core/VibrationRequestProvider";
import { SpectraRequestProvider } from "../core/SpectraRequestProvider";
import { TemperatureResponseProvider } from "../core/TemperatureResponseProvider";
import { AccelerationResponseProvider } from "../core/AccelerationResponseProvider";
import { VelocityResponseProvider } from "../core/VelocityResponseProvider";
import { VibrationResponseProvider } from "../core/VibrationResponseProvider";
import { SpectraResponseProvider } from "../core/SpectraResponseProvider";
import { LinearTemperature } from "./components/LinearTemperature";
import { AccelerationChart } from "./AccelerationChart";
import { VelocityChart } from "./VelocityChart";
import { VibrationChart } from "./VibrationChart";
import { WaterfallChart } from "./WaterfallChart";
import { Waterfall3dChart } from "./Waterfall3dChart";
import { WaveChart } from "./WaveChart";
import { SpectraConfig } from "./components/SpectraConfig";
import { useSpectraRequest } from "../core/SpectraRequestProvider";
import { Toast } from "../../../../../theme/widgets/alerts/Toast";
import { captureSpectraData } from "../core/_requests";
import { Loading } from "../../../../../theme/widgets/loading/Loading";
import { stringifyRequestQuery } from "../../../../../theme/helpers/CrudHelpers";

const SpectraView = () => {
  const { state, updateState } = useSpectraRequest();
  const { equipmentId } = useParams();
  const queryClient = useQueryClient();
  const query = stringifyRequestQuery(state)

  const captureSpectra = useMutation(
    (equipmentId: number) => captureSpectraData(equipmentId),
    {
      onSuccess: (response) => {
        Toast.fire({
          icon: "success",
          title: response.message,
          customClass: {
            popup: "bg-success",
            title: "text-light",
          },
        });
        queryClient.invalidateQueries([`ACODATA_IOT_LIST_SPECTRA-${query}`]);
        queryClient.invalidateQueries(['SPECTRA_LIST']);
      },
      onError: (error: any) => {
        console.log(error);
        error.response.data.data.name.map((message: string) => {
          Toast.fire({
            icon: "error",
            iconHtml: "<span>!</span>",
            title: message,
            customClass: {
              popup: "bg-danger",
              title: "text-light",
            },
          });
        });
      },
    }
  );

  return (
    <>
      <div className="row justify-content-between align-items-center border border-color-ccc shadow-sm rounded bg-white col-12 mx-auto mt-3 py-4 col-lg-11">
        <div className="col-12 text-end">
          {state.filter?.capturas && (
            <button
              type="button"
              className="btn btn-primary me-4"
              onClick={() => updateState({ filter: {} })}
            >
              Espectro
            </button>
          )}
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#spectra-settings-modal"
            className="btn btn-primary me-4"
          >
            Gráfico em Cascata
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => captureSpectra.mutateAsync(parseInt(equipmentId!))}
          >
            Coletar espectro agora
          </button>
        </div>
        {!state.filter?.capturas ? (
          <>
            <WaterfallChart /> <WaveChart />
          </>
        ) : (
          <Waterfall3dChart />
        )}
        <div className="col-12">
          <div className="row">
            <div className="col-4 mb-3">
              <h4 className="mb-0 font-weight-bold">Gráficos de temperatura</h4>
            </div>
            <div className="col-12">
              <LinearTemperature nodeId="temparature_chart" />
            </div>
          </div>
        </div>

        <AccelerationChart />

        <VelocityChart />

        <VibrationChart />
      </div>
      <SpectraConfig />

      {captureSpectra.isLoading && <Loading />}
    </>
  );
};

const SpectraViewWrapper = () => (
  <SpectraRequestProvider>
    <SpectraResponseProvider>
      <TemperatureRequestProvider>
        <TemperatureResponseProvider>
          <AccelerationRequestProvider>
            <AccelerationResponseProvider>
              <VelocityRequestProvider>
                <VelocityResponseProvider>
                  <VibrationRequestProvider>
                    <VibrationResponseProvider>
                      <SpectraView />
                    </VibrationResponseProvider>
                  </VibrationRequestProvider>
                </VelocityResponseProvider>
              </VelocityRequestProvider>
            </AccelerationResponseProvider>
          </AccelerationRequestProvider>
        </TemperatureResponseProvider>
      </TemperatureRequestProvider>
    </SpectraResponseProvider>
  </SpectraRequestProvider>
);

export { SpectraViewWrapper };
