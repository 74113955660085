import { Routes, Outlet, Route } from "react-router-dom";
import { EquipmentList } from "./components/equipment-list/EquipmentList";
import { EquipmentEdit } from "./components/equipment-edit/EquipmentEdit";
import { useAuth } from "../../modules/auth";

const EquipmentPage = () => {
  const { currentUser } = useAuth();
  const equipmentPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 6;
  })[0];

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="/" element={<EquipmentList />} />
        {(!!equipmentPermissions?.create ||
          currentUser?.profile.name === "administrador") && (
          <Route path="/new" element={<EquipmentEdit />} />
        )}
        {(!!equipmentPermissions?.update ||
          currentUser?.profile.name === "administrador") && (
          <Route path=":equipmentId" element={<EquipmentEdit />} />
        )}
      </Route>
    </Routes>
  );
};

export default EquipmentPage;
