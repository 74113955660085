import { useState, FC, useEffect, ChangeEvent } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import {
  Profile,
  ProfileInitialValue,
  ProfilePermission,
} from "../core/_models";
import { createProfile, updateProfile, getModules } from "../core/_requests";
import { Toast } from "../../../../../theme/widgets/alerts/Toast";
import { useAuth } from "../../../../modules/auth";

type ProfilesFormProps = {
  profile: Profile;
};

const ProfilesForm: FC<ProfilesFormProps> = ({ profile }) => {
  const { profileId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [profilePermissions, setProfilePermissions] = useState<
    Array<ProfilePermission>
  >([]);

  const {
    isFetching,
    refetch,
    data: modules,
  } = useQuery(
    `PERMISSIONS_LIST`,
    () => {
      return getModules();
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const [profileEdit] = useState<Profile>({
    ...profile,
    name: profile.name || ProfileInitialValue.name,
    user_permissions:
      profile.user_permissions || ProfileInitialValue.user_permissions,
  });

  useEffect(() => {
    var tempProfilePermissions: ProfilePermission[] = [];
    modules?.map((module, index) => {
      if (module.id) {
        var moduleCurrentPermissions = profile.user_permissions?.filter(
          (permission) => {
            return permission.module_id === module.id;
          }
        );
        if (moduleCurrentPermissions?.length) {
          tempProfilePermissions[module?.id] = {
            create: moduleCurrentPermissions[0].create,
            read: moduleCurrentPermissions[0].read,
            update: moduleCurrentPermissions[0].update,
            delete: moduleCurrentPermissions[0].delete,
          };
        } else {
          tempProfilePermissions[module?.id] = {
            create: false,
            read: false,
            update: false,
            delete: false,
          };
        }
      }
      setProfilePermissions(tempProfilePermissions);
    });
  }, [modules]);

  const updatePermissions =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      let newArr: any[] = [...profilePermissions];
      newArr[index][e.target.value as keyof ProfilePermission]! =
        e.target.checked;

      setProfilePermissions(newArr);
    };

  const update = useMutation((values: Profile) => updateProfile(values), {
    onSuccess: (response) => {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          popup: "bg-success",
          title: "text-light",
        },
      });
    },
    onError: (error: any) => {
      console.log(error);
      Toast.fire({
        icon: "error",
        iconHtml: "<span>!</span>",
        title: "Erro ao cadastrar perfil.",
        customClass: {
          popup: "bg-danger",
          title: "text-light",
        },
      });
    },
  });

  const create = useMutation((values: Profile) => createProfile(values), {
    onSuccess: (response) => {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          popup: "bg-success",
          title: "text-light",
        },
      });
      navigate(-1);
    },
    onError: (error: any) => {
      console.log(error);
      error.response.data.data.name.map((message: string) => {
        Toast.fire({
          icon: "error",
          iconHtml: "<span>!</span>",
          title: message,
          customClass: {
            popup: "bg-danger",
            title: "text-light",
          },
        });
      });
    },
  });

  const formik = useFormik({
    initialValues: profileEdit,
    onSubmit: async (values, { setSubmitting }) => {
      values.user_permissions = profilePermissions;
      try {
        if (profileId) {
          return update.mutateAsync(values);
        } else {
          return create.mutateAsync(values);
        }
      } catch (ex) {
        console.error(ex);
      } finally {
      }
    },
  });

  return (
    <>
      <div className="mt-3 row justify-content-between align-items-center border border-color-ccc shadow-sm rounded bg-white col-11 mx-auto py-3 ">
        <div className="row mb-5">
          <div className="col-12">
            <h2>{`${profile.id ? "Editar" : "Novo"} Perfil`}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-3 mb-3">
                  <div className="form-group">
                    <label htmlFor="name">
                      Nome <span className="text-danger">*</span>
                    </label>
                    <input
                      {...formik.getFieldProps("name")}
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      required
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {currentUser?.profile.name === "administrador" &&
                  modules &&
                  modules.map((module, index) => (
                    <div className="col-12">
                      <div className="d-flex align-items-center border rounded my-2 py-2 px-3">
                        <div className="fw-bold me-4">{module.name}</div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`create_${module.id}`}
                            value="create"
                            checked={profilePermissions[module.id!]?.create}
                            onChange={updatePermissions(module.id!)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`create_${module.id}`}
                          >
                            Criar
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`read_${module.id}`}
                            value="read"
                            checked={profilePermissions[module.id!]?.read}
                            onChange={updatePermissions(module.id!)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`read_${module.id}`}
                          >
                            Visualizar
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`update_${module.id}`}
                            value="update"
                            checked={profilePermissions[module.id!]?.update}
                            onChange={updatePermissions(module.id!)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`update_${module.id}`}
                          >
                            Editar
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`delete_${module.id}`}
                            value="delete"
                            checked={profilePermissions[module.id!]?.delete}
                            onChange={updatePermissions(module.id!)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`delete_${module.id}`}
                          >
                            Apagar
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="row  mt-5">
                <div className="col-12">
                  <Link to={"/profiles"} className="btn btn-danger">
                    <i className="bi bi-arrow-left"></i> Voltar
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-primary ms-3"
                    disabled={
                      formik.isSubmitting || !formik.isValid || !formik.touched
                    }
                  >
                    <span>Salvar</span>
                    {formik.isSubmitting && (
                      <div
                        className="spinner-border spinner-border-sm ms-2"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export { ProfilesForm };
