import { Routes, Outlet, Route } from "react-router-dom";
import { CellsList } from "./components/cells-list/CellsList";
import { CellsEdit } from "./components/cells-edit/CellsEdit";
import { useAuth } from "../../modules/auth";

const CellsPage = () => {
  const { currentUser } = useAuth();
  const cellPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 5;
  })[0];

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="/" element={<CellsList />} />
        {(!!cellPermissions?.create ||
          currentUser?.profile.name === "administrador") && (
          <Route path="/new" element={<CellsEdit />} />
        )}
        {(!!cellPermissions?.update ||
          currentUser?.profile.name === "administrador") && (
          <Route path=":cellId" element={<CellsEdit />} />
        )}
      </Route>
    </Routes>
  );
};

export default CellsPage;
