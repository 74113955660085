import { Routes, Outlet, Route } from "react-router-dom";
import { UsersList } from "./components/users-list/UsersList";
import { UsersEdit } from "./components/users-edit/UsersEdit";
import { useAuth } from "../../modules/auth";

const UsersPage = () => {
  const { currentUser } = useAuth();
  const userPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 1;
  })[0];

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="/" element={<UsersList />} />
        {(!!userPermissions?.create ||
          currentUser?.profile.name === "administrador") && (
          <Route path="/new" element={<UsersEdit />} />
        )}
        {(!!userPermissions?.update ||
          currentUser?.profile.name === "administrador") && (
          <Route path=":userId" element={<UsersEdit />} />
        )}
      </Route>
    </Routes>
  );
};

export default UsersPage;
