import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios'
import {QueryClient, QueryClientProvider} from 'react-query'
import { SciChartSurface, SciChart3DSurface } from 'scichart';
import reportWebVitals from './reportWebVitals';
import { AppRoutes } from './app/routes/AppRoutes';
import { setupAxios, AuthProvider } from './app/modules/auth';

import './theme/assets/scss/custom.scss';
import 'bootstrap-icons/font/bootstrap-icons.css'
import "bootstrap"
import 'bootstrap/dist/js/bootstrap.bundle'

setupAxios(axios)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
})

// Set this code in application startup, before creating a SciChartSurface
SciChartSurface.setRuntimeLicenseKey(process.env.REACT_APP_SCICHART_RUNTIME_KEY!);
SciChart3DSurface.setRuntimeLicenseKey(process.env.REACT_APP_SCICHART_RUNTIME_KEY!);
SciChartSurface.loadWasmFromCDN();
SciChart3DSurface.loadWasmFromCDN();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  </QueryClientProvider>
);

reportWebVitals();
