export type AlarmParameterization = {
  id?: number;
  alarm_limits_min?: number;
  alarm_limits_max?: number;
  data_collection_time?: number;
  spectrum_collection?: string;
  description?: string;
};

export type ResponseAlarmParameterization = {
  message: string;
};

export const AlarmParameterizationInitialValue: AlarmParameterization = {
  id: undefined,
  alarm_limits_min: 0,
  alarm_limits_max: 0,
  data_collection_time: 1,
  spectrum_collection: "",
  description: "",
};
