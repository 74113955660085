import { FC, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { Client, ClientInitialValue } from "../core/_models";
import { createClient, updateClient } from "../core/_requests";
import { Toast } from "../../../../../theme/widgets/alerts/Toast";

type ClientFormProps = {
  client: Client;
};

const ClientsForm: FC<ClientFormProps> = ({ client }) => {
  const { clientId } = useParams();
  const navigate = useNavigate();

  const [clientEdit] = useState<Client>({
    ...client,
    code: client.code || ClientInitialValue.code,
    company_name: client.company_name || ClientInitialValue.company_name,
    business_name: client.business_name || ClientInitialValue.business_name,
    address: client.address || ClientInitialValue.address,
    zip_code: client.zip_code || ClientInitialValue.zip_code,
    contact_email: client.contact_email || ClientInitialValue.contact_email,
    contact_name: client.contact_name || ClientInitialValue.contact_name,
  });

  const update = useMutation((values: Client) => updateClient(values), {
    onSuccess: (response) => {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          popup: "bg-success",
          title: "text-light",
        },
      });
    },
    onError: (error: any) => {
      console.log(error);
      Toast.fire({
        icon: "error",
        iconHtml: "<span>!</span>",
        title: "Erro ao cadastrar cliente.",
        customClass: {
          popup: "bg-danger",
          title: "text-light",
        },
      });
    },
  });

  const create = useMutation((values: Client) => createClient(values), {
    onSuccess: (response) => {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          popup: "bg-success",
          title: "text-light",
        },
      });
      navigate(-1);
    },
    onError: (error: any) => {
      console.log(error);
      error.response.data.data.name.map((message: string) => {
        Toast.fire({
          icon: "error",
          iconHtml: "<span>!</span>",
          title: message,
          customClass: {
            popup: "bg-danger",
            title: "text-light",
          },
        });
      });
    },
  });

  const formik = useFormik({
    initialValues: clientEdit,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        if (clientId) {
          return update.mutateAsync(values);
        } else {
          return create.mutateAsync(values);
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <div className="mt-3 row justify-content-between align-items-center border border-color-ccc shadow-sm rounded bg-white col-11 mx-auto py-3 ">
        <div className="row mb-5">
          <div className="col-12">
            <h2>{`${client.id ? "Editar" : "Novo"} Cliente`}</h2>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="code">
                      Código <span className="text-danger">*</span>
                    </label>
                    <input
                      {...formik.getFieldProps("code")}
                      type="number"
                      name="code"
                      id="code"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="company_name">
                      Razão Social <span className="text-danger">*</span>
                    </label>
                    <input
                      {...formik.getFieldProps("company_name")}
                      type="text"
                      name="company_name"
                      id="company_name"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="business_name">
                      Nome Fantasia <span className="text-danger">*</span>
                    </label>
                    <input
                      {...formik.getFieldProps("business_name")}
                      type="text"
                      name="business_name"
                      id="business_name"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="address">
                      Endereço <span className="text-danger">*</span>
                    </label>
                    <input
                      {...formik.getFieldProps("address")}
                      type="text"
                      name="address"
                      id="address"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="zip_code">
                      CEP <span className="text-danger">*</span>
                    </label>
                    <input
                      {...formik.getFieldProps("zip_code")}
                      type="text"
                      name="zip_code"
                      id="zip_code"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="contact_email">
                      E-mail de Contato <span className="text-danger">*</span>
                    </label>
                    <input
                      {...formik.getFieldProps("contact_email")}
                      type="email"
                      name="contact_email"
                      id="contact_email"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="contact_name">
                      Nome de Contato <span className="text-danger">*</span>
                    </label>
                    <input
                      {...formik.getFieldProps("contact_name")}
                      type="text"
                      name="contact_name"
                      id="contact_name"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row  mt-5">
                <div className="col-12">
                  <Link to={"/clients"} className="btn btn-danger">
                    <i className="bi bi-arrow-left"></i> Voltar
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-primary ms-3"
                    disabled={
                      formik.isSubmitting || !formik.isValid || !formik.touched
                    }
                  >
                    <span>Salvar</span>
                    {formik.isSubmitting && (
                      <div
                        className="spinner-border spinner-border-sm ms-2"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export { ClientsForm };
