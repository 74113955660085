import { ReactNode } from "react";
import { LinearChartModel, SpectraCaptura } from "../../app/pages/spectra/components/core/_models";

export type WithChildren = {
  children?: ReactNode;
};

type Filter = {
  capturas?: number[]
  start_date?: string
  end_date?: string
  date?: string
  data_type?: string
}

export type Search = {
  search?: string;
  filter?: Filter
};

export type QueryRequestContextProps = {
  state: Search;
  updateState: (updates: Partial<Search>) => void;
};

export const initialQueryRequest: QueryRequestContextProps = {
  state: { search: "" },
  updateState: () => {},
};

export type Response<T> = {
    data: T
}

export type QueryResponseContextProps<T> = {
  response?: LinearChartModel[] | undefined;
  refetch: () => void;
  isLoading: boolean;
  query: string;
};

export type SpectraQueryResponseContextProps<T> = {
  response?: SpectraCaptura[] | undefined;
  refetch: () => void;
  isLoading: boolean;
  query: string;
};

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}
