export type Client = {
    id?: number,
    code?: number,
    company_name?: string,
    business_name?: string,
    address	?: string,
    zip_code?: string,
    contact_email?: string,
    contact_name?: string
}

export type ResponseClient = {
    message: string
}

export const ClientInitialValue: Client = {
    id: 0,
    code: undefined,
    company_name: '',
    business_name: '',
    address: '',
    zip_code: '',
    contact_email: '',
    contact_name: ''
}