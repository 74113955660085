import { toAbsoluteUrl } from "../../helpers/AssetsHelpers";

const Loading = () => {
  const styles = {
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    width: "100px",
    height: "100px",
    zIndex: "99999",
    background: "#ffffff",
    borderRadius: "7px",
  };

  return (
    <div style={{ ...styles, position: "absolute", textAlign: "center" }}>
      <img
        style={{ width: "100%", height: "100%", objectFit: "contain" }}
        src={toAbsoluteUrl("/loading.gif")}
        alt="TaskRush"
      />
    </div>
  );
};

export { Loading };
