import { FC, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { Area, AreaInitialValue } from "../core/_models";
import { createArea, updateArea } from "../core/_requests";
import { Toast } from "../../../../../theme/widgets/alerts/Toast";

type AreaFormProps = {
  area: Area;
};

const AreasForm: FC<AreaFormProps> = ({ area }) => {
  const { areaId } = useParams();
  const navigate = useNavigate();

  const [areaEdit] = useState<Area>({
    ...area,
    area: area.area || AreaInitialValue.area,
  });

  const update = useMutation((values: Area) => updateArea(values), {
    onSuccess: (response) => {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          popup: "bg-success",
          title: "text-light",
        },
      });
    },
    onError: (error: any) => {
      console.log(error);
      Toast.fire({
        icon: "error",
        iconHtml: "<span>!</span>",
        title: "Erro ao cadastrar a área.",
        customClass: {
          popup: "bg-danger",
          title: "text-light",
        },
      });
    },
  });

  const create = useMutation((values: Area) => createArea(values), {
    onSuccess: (response) => {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          popup: "bg-success",
          title: "text-light",
        },
      });
      navigate(-1);
    },
    onError: (error: any) => {
      console.log(error);
      error.response.data.data.name.map((message: string) => {
        Toast.fire({
          icon: "error",
          iconHtml: "<span>!</span>",
          title: message,
          customClass: {
            popup: "bg-danger",
            title: "text-light",
          },
        });
      });
    },
  });

  const formik = useFormik({
    initialValues: areaEdit,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        if (areaId) {
          return update.mutateAsync(values);
        } else {
          return create.mutateAsync(values);
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>

<div className="mt-3 row justify-content-between align-items-center border border-color-ccc shadow-sm rounded bg-white col-11 mx-auto py-3 ">
      <div className="row mb-5">
        <div className="col-12">
          <h2>{`${area.id ? "Editar" : "Nova"} Área`}</h2>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="area">
                    Área <span className="text-danger">*</span>
                  </label>
                  <input
                    {...formik.getFieldProps("area")}
                    type="string"
                    name="area"
                    id="area"
                    className="form-control"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row  mt-5">
              <div className="col-12">
                <Link to={"/areas"} className="btn btn-danger">
                  <i className="bi bi-arrow-left"></i> Voltar
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary ms-3"
                  disabled={
                    formik.isSubmitting || !formik.isValid || !formik.touched
                  }
                >
                  <span>Salvar</span>
                  {formik.isSubmitting && (
                    <div
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      </div>
    </>
  );
};

export { AreasForm };
