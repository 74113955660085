import { FC, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { Cell, CellInitialValue } from "../core/_models";
import { createCell, updateCell } from "../core/_requests";
import { Toast } from "../../../../../theme/widgets/alerts/Toast";
import { getAreas } from "../../../areas/components/core/_requests";

type CellFormProps = {
  cell: Cell;
};

const CellsForm: FC<CellFormProps> = ({ cell }) => {
  const { cellId } = useParams();
  const navigate = useNavigate();

  const { data: areas } = useQuery(
    `AREAS_LIST`,
    () => {
      return getAreas();
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const [cellEdit] = useState<Cell>({
    ...cell,
    cell: cell.cell || CellInitialValue.cell,
    area_id: cell.area_id || CellInitialValue.area_id,
  });

  const update = useMutation((values: Cell) => updateCell(values), {
    onSuccess: (response) => {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          popup: "bg-success",
          title: "text-light",
        },
      });
    },
    onError: (error: any) => {
      console.log(error);
      Toast.fire({
        icon: "error",
        iconHtml: "<span>!</span>",
        title: "Erro ao cadastrar a célula'.",
        customClass: {
          popup: "bg-danger",
          title: "text-light",
        },
      });
    },
  });

  const create = useMutation((values: Cell) => createCell(values), {
    onSuccess: (response) => {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          popup: "bg-success",
          title: "text-light",
        },
      });
      navigate(-1);
    },
    onError: (error: any) => {
      console.log(error);
      error.response.data.data.name.map((message: string) => {
        Toast.fire({
          icon: "error",
          iconHtml: "<span>!</span>",
          title: message,
          customClass: {
            popup: "bg-danger",
            title: "text-light",
          },
        });
      });
    },
  });

  const formik = useFormik({
    initialValues: cellEdit,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        if (cellId) {
          return update.mutateAsync(values);
        } else {
          return create.mutateAsync(values);
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
    <div className="mt-3 row justify-content-between align-items-center border border-color-ccc shadow-sm rounded bg-white col-11 mx-auto py-3 ">
      <div className="row mb-5">
        <div className="col-12">
          <h2>{`${cell.id ? "Editar" : "Nova"} Célula`}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <form onSubmit={formik.handleSubmit}>
            <div className="row gap-2">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="cell">
                    Célula <span className="text-danger">*</span>
                  </label>
                  <input
                    {...formik.getFieldProps("cell")}
                    type="string"
                    name="cell"
                    id="cell"
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="profile_id">
                    Área <span className="text-danger">*</span>
                  </label>
                  <select
                    {...formik.getFieldProps("area_id")}
                    required
                    name="area_id"
                    id="area_id"
                    className="form-select"
                  >
                    <option value="">Selecione uma área</option>
                    {areas &&
                      areas.map((area, index) => (
                        <option key={index} value={area.id}>
                          {area.area}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row  mt-5">
              <div className="col-12">
                <Link to={"/cells"} className="btn btn-danger">
                  <i className="bi bi-arrow-left"></i> Voltar
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary ms-3"
                  disabled={
                    formik.isSubmitting || !formik.isValid || !formik.touched
                  }
                >
                  <span>Salvar</span>
                  {formik.isSubmitting && (
                    <div
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  );
};

export { CellsForm };
