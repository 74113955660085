/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  WithChildren,
  initialQueryResponse,
} from "../../../../../theme/helpers/Model";
import {
  createResponseContext,
  stringifyRequestQuery,
  isNotEmpty,
} from "../../../../../theme/helpers/CrudHelpers";
import { getCaptures } from "./_requests";
import { LinearChartModel } from "./_models";
import { useVibrationRequest } from "./VibrationRequestProvider";

const VibrationResponseContext =
  createResponseContext<LinearChartModel>(initialQueryResponse);
const VibrationResponseProvider: FC<WithChildren> = ({ children }) => {
  const { equipmentId } = useParams();
  const { state } = useVibrationRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const enabledQuery: boolean = isNotEmpty(query);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `ACODATA_IOT_LIST_VIBRATION-${query}`,
    () => {
      return getCaptures(parseInt(equipmentId!), query);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
    }
  );

  return (
    <VibrationResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </VibrationResponseContext.Provider>
  );
};

const useVibrationResponse = () => useContext(VibrationResponseContext);

const useVibrationResponseData = () => {
  const { response } = useVibrationResponse();

  if (!response) {
    return [];
  }

  return response || [];
};

const useVibrationResponseLoading = (): boolean => {
  const { isLoading } = useVibrationResponse();
  return isLoading;
};

export {
  VibrationResponseProvider,
  useVibrationResponse,
  useVibrationResponseData,
  useVibrationResponseLoading,
};
