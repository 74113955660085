import { Routes, Outlet, Route } from "react-router-dom";
import { SpectraViewWrapper } from "./components/spectra-view/SpectraView";

const SpectraPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path=":equipmentId" element={<SpectraViewWrapper />} />
      </Route>
    </Routes>
  );
};

export default SpectraPage;
