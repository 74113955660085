import { useRef, Fragment, useCallback, useEffect } from "react";
//@ts-ignore
import { FFT } from "dsp.js";
import {
  DefaultPaletteProvider,
  CustomAnnotation,
  FastLineRenderableSeries,
  SciChartSurface,
  SciChartJsNavyTheme,
  NumericAxis,
  EAxisAlignment,
  NumberRange,
  LogarithmicAxis,
  ENumericFormat,
  EHorizontalAnchorPoint,
  EVerticalAnchorPoint,
  ECoordinateMode,
  EXyDirection,
  MouseWheelZoomModifier,
  ZoomExtentsModifier,
  IRenderableSeries,
  SeriesSelectionModifier,
  LegendModifier,
  AnnotationDragDeltaEventArgs,
  AnnotationClickEventArgs,
  EAutoRange,
  FastMountainRenderableSeries,
  GradientParams,
  Point,
  ELegendOrientation,
  Thickness,
  XyDataSeries,
  RubberBandXyZoomModifier,
  WaveAnimation,
  RolloverModifier,
} from "scichart";
import { toJpeg } from "html-to-image";
import { useSpectraResponseData } from "../core/SpectraResponseProvider";
import { useSpectraResponseLoading } from "../core/SpectraResponseProvider";
import { useSpectraRequest } from "../core/SpectraRequestProvider";
import { Loading } from "../../../../../theme/widgets/loading/Loading";

class CrossSectionPaletteProvider extends DefaultPaletteProvider {
  public selectedIndex: number = -1;
  public shouldUpdate: boolean = true;

  public shouldUpdatePalette(): boolean {
    return this.shouldUpdate;
  }
}

export const divMainChartId = "sciChart1";
export const divCrossSection1 = "sciChart2";
export const divCrossSection2 = "sciChart3";

let mainChartSurface: SciChartSurface;
const crossSectionPaletteProvider = new CrossSectionPaletteProvider();
let dragMeAnnotation: CustomAnnotation;
let promptAnnotation: CustomAnnotation;
let mainChartSelectionModifier: SeriesSelectionModifier;

const WaterfallChart = () => {
  const { updateState } = useSpectraRequest();
  const data = useSpectraResponseData();
  const isLoading = useSpectraResponseLoading();
  const ref = useRef<HTMLDivElement>(null);
  const series = ["axial", "horizontal", "vertical"];
  const seriesColors = ["#838485", "#04ba13", "#ff6600"];

  useEffect(() => {
    getSpectraData();
  }, []);

  const getSpectraData = () => {
    updateState({ filter: { date: "20" } });
  };

  const theme = new SciChartJsNavyTheme();
  theme.sciChartBackground = "#205a89";

  let crossSectionSelectedSeries: IRenderableSeries;
  let crossSectionHoveredSeries: IRenderableSeries;
  let crossSectionSliceSeries: XyDataSeries;
  let crossSectionLegendModifier: LegendModifier;

  const createSpectralData = async (serie: string) => {
    //@ts-ignore
    const timeData: number[] = data[0].capture[serie];

    const sampleRate = 16000; // Taxa de amostragem em Hz
    const maxFrequency = 6000; // Frequência máxima a ser mostrada

    // Criando o objeto FFT
    const fft = new FFT(timeData.length, sampleRate); // 44100 é a taxa de amostragem, ajuste conforme necessário

    // Executando a FFT nos valores
    fft.forward(timeData);

    // Extraindo as magnitudes das frequências
    const magnitudes = fft.spectrum; // Frequências associadas

    // Frequências associadas
    const frequencies = Array.from(
      { length: timeData.length / 2 },
      (_, i) => (i / timeData.length) * sampleRate
    );

    // Filtrar frequências para exibir apenas de 0 a 6000 Hz
    const filteredMagnitudes = magnitudes
      .slice(0, timeData.length / 2)
      .filter((_: any, i: any) => frequencies[i] <= maxFrequency);
    const filteredFrequencies = frequencies.filter(
      (freq) => freq <= maxFrequency
    );

    const xValues = filteredFrequencies;
    const yValues = filteredMagnitudes;

    return { xValues, yValues };
  };

  useEffect(() => {
    if (data.length) {
      initMainChart().then(() => {
        initCrossSectionLeft();
        initCrossSectionRight().then(() => {
          configureAfterInit(takeChartScreenshot);
        });
      });
    }
  }, [data]);

  // This function creates the main chart with waterfall series
  // To do this, we create N series, each with its own X,Y axis with a different X,Y offset
  // all axis other than the first are hidden
  const initMainChart = async () => {
    const { sciChartSurface, wasmContext } = await SciChartSurface.create(
      divMainChartId,
      {
        disableAspect: true,
        theme,
      }
    );

    mainChartSurface = sciChartSurface;

    var seriesCount = series.length;

    for (let i = 0; i < seriesCount; i++) {
      // Create some data for the example
      const { xValues, yValues } = await createSpectralData(series[i]);

      // Create one yAxis per series
      const yAxis = new NumericAxis(wasmContext, {
        id: "Y" + i,
        axisAlignment: EAxisAlignment.Left,
        maxAutoTicks: 5,
        drawMinorGridLines: false,
        visibleRange: new NumberRange(-8, 30),
        isVisible: i === seriesCount - 1,
        overrideOffset: 3 * -i,
      });
      sciChartSurface.yAxes.add(yAxis);

      // Create a shared, default xaxis
      const xAxis = new NumericAxis(wasmContext, {
        id: "X" + i,
        labelFormat: ENumericFormat.SignificantFigures,
        axisTitleStyle: { fontSize: 10 },
        growBy: new NumberRange(0.05, 0.2),
        isVisible: i === seriesCount - 1,
        overrideOffset: 2 * i,
      });
      sciChartSurface.xAxes.add(xAxis);

      mainChartSurface.rendered.subscribe(() => {
        // Don't recalculate the palette unless the selected index changes
        crossSectionPaletteProvider.shouldUpdate = false;
      });
      const lineSeries = new FastLineRenderableSeries(wasmContext, {
        id: "S" + i,
        xAxisId: "X" + i,
        yAxisId: "Y" + i,
        stroke: seriesColors[i],
        strokeThickness: 2,
        dataSeries: new XyDataSeries(wasmContext, {
          xValues,
          yValues,
          dataSeriesName: series[i].replace(/^./, series[i][0].toUpperCase()),
        }),
        paletteProvider: crossSectionPaletteProvider,
      });
      // Insert series in reverse order so the ones at the bottom of the chart are drawn first
      // sciChartSurface.renderableSeries.insert(0, lineSeries);
      sciChartSurface.renderableSeries.add(lineSeries);

      const waveAnimation = new WaveAnimation({
        zeroLine: 0,
        pointDurationFraction: 0.5,
        duration: 2000,
        fadeEffect: true,
        delay: 1000,
      });
      lineSeries.enqueueAnimation(waveAnimation);
    }

    sciChartSurface.chartModifiers.add(
      new LegendModifier({ showCheckboxes: true })
    );

    // Add a tooltip behavior using the RolloverModifier
    // @ts-ignore
    const tooltipModifier = new RolloverModifier(wasmContext);
    sciChartSurface.chartModifiers.add(tooltipModifier);

    // Add an annotation which can be dragged horizontally to update the bottom right chart
    dragMeAnnotation = new CustomAnnotation({
      svgString: `<svg xmlns="http://www.w3.org/2000/svg" width="100" height="82">
                  <g>
                    <line x1="50%" y1="10" x2="50%" y2="40" stroke="#FFBE93" stroke-dasharray="2,2" />
                    <circle cx="50%" cy="10" r="5" fill="#FFBE93" />
                    <rect x="2" y="40" rx="10" ry="10" width="96" height="40" fill="#64BAE433" stroke="#64BAE4" stroke-width="2" />
                    <text x="50%" y="60" fill="White" text-anchor="middle" alignment-baseline="middle" >Arraste</text>
                  </g>
                </svg>`,
      x1: 133,
      y1: 1,
      xAxisId: "X0",
      yAxisId: "Y0",
      isEditable: true,
      annotationsGripsFill: "Transparent",
      annotationsGripsStroke: "Transparent",
      selectionBoxStroke: "Transparent",
      horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
      verticalAnchorPoint: EVerticalAnchorPoint.Top,
      xCoordinateMode: ECoordinateMode.DataValue,
      yCoordinateMode: ECoordinateMode.DataValue,
    });
    sciChartSurface.annotations.add(dragMeAnnotation);

    // Place an annotation with further instructions in the top right of the chart
    promptAnnotation = new CustomAnnotation({
      svgString: `<svg xmlns="http://www.w3.org/2000/svg" width="120" height="82" style="cursor:pointer">  
                <style>
                svg { cursor: pointer; } /* whole rectangle */

                /* OR */

                .element-name { cursor: pointer; } /* specific elements */
            </style>
            <g>
                <rect x="20" y="2" width="98" height="40" rx="10" ry="10" fill="#64BAE433" stroke="#64BAE4" stroke-width="2" />
                <text x="68" y="24" fill="white" text-anchor="middle" alignment-baseline="middle" font-size="12">Exportar gráfico</text>
            </g>
            </svg>`,
      xAxisId: "X0",
      yAxisId: "Y0",
      isEditable: false,
      xCoordinateMode: ECoordinateMode.Relative,
      yCoordinateMode: ECoordinateMode.Relative,
      horizontalAnchorPoint: EHorizontalAnchorPoint.Right,
      verticalAnchorPoint: EVerticalAnchorPoint.Top,
      x1: 0.98,
      y1: 0.03,
    });

    sciChartSurface.annotations.add(promptAnnotation);

    // Add zooming behaviours
    sciChartSurface.chartModifiers.add(
      // new ZoomPanModifier({ xyDirection: EXyDirection.XDirection }),
      new RubberBandXyZoomModifier({ xyDirection: EXyDirection.XDirection }),
      new MouseWheelZoomModifier({ xyDirection: EXyDirection.XDirection }),
      new ZoomExtentsModifier({ xyDirection: EXyDirection.XDirection })
    );

    const updateSeriesSelectionState = (series: IRenderableSeries) => {
      // series.stroke = series.isSelected
      //   ? "White"
      //   : series.isHovered
      //   ? "#FFBE93"
      //   : "#64BAE4";
      series.strokeThickness = series.isSelected || series.isHovered ? 3 : 2;
    };

    let prevSelectedSeries: IRenderableSeries =
      sciChartSurface.renderableSeries.get(0);
    // Add selection behaviour
    mainChartSelectionModifier = new SeriesSelectionModifier({
      enableHover: true,
      enableSelection: true,
      hitTestRadius: 5,
      onSelectionChanged: (args) => {
        if (args.selectedSeries.length > 0) {
          prevSelectedSeries = args.selectedSeries[0];
          args.allSeries.forEach(updateSeriesSelectionState);
        } else {
          prevSelectedSeries.isSelected = true;
        }
      },
      onHoverChanged: (args) => {
        args.allSeries.forEach(updateSeriesSelectionState);
      },
    });
    sciChartSurface.chartModifiers.add(mainChartSelectionModifier);
    return { sciChartSurface };
  };

  // In the bottom left chart, add two series to show the currently hovered/selected series on the main chart
  // These will be updated in the selection callback below
  const initCrossSectionLeft = async () => {
    const { sciChartSurface, wasmContext } = await SciChartSurface.create(
      divCrossSection1,
      {
        disableAspect: true,
        theme,
      }
    );

    sciChartSurface.xAxes.add(
      new NumericAxis(wasmContext, {
        autoRange: EAutoRange.Always,
        drawMinorGridLines: false,
        growBy: new NumberRange(0.005, 0.2),
      })
    );
    sciChartSurface.yAxes.add(
      new NumericAxis(wasmContext, {
        autoRange: EAutoRange.Never,
        axisAlignment: EAxisAlignment.Left,
        visibleRange: new NumberRange(0, 25),
        drawMinorGridLines: false,
      })
    );

    crossSectionSelectedSeries = new FastLineRenderableSeries(wasmContext, {
      stroke: "#ff6600",
      strokeThickness: 3,
    });
    sciChartSurface.renderableSeries.add(crossSectionSelectedSeries);
    crossSectionHoveredSeries = new FastMountainRenderableSeries(wasmContext, {
      stroke: "#64BAE477",
      strokeThickness: 3,
      strokeDashArray: [2, 2],
      fillLinearGradient: new GradientParams(new Point(0, 0), new Point(0, 1), [
        { color: "#64BAE455", offset: 0 },
        { color: "#64BAE400", offset: 1 },
      ]),
      dataSeries: crossSectionSliceSeries,
      zeroLineY: -999,
    });
    sciChartSurface.renderableSeries.add(crossSectionHoveredSeries);

    // Add a legend to the bottom left chart
    crossSectionLegendModifier = new LegendModifier({
      showCheckboxes: false,
      orientation: ELegendOrientation.Horizontal,
    });
    crossSectionLegendModifier.isEnabled = false;
    sciChartSurface.chartModifiers.add(crossSectionLegendModifier);

    return { sciChartSurface };
  };

  const initCrossSectionRight = async () => {
    const { sciChartSurface, wasmContext } = await SciChartSurface.create(
      divCrossSection2,
      {
        disableAspect: true,
        theme,
        title: "Seção transversal",
        titleStyle: {
          fontSize: 13,
          padding: Thickness.fromNumber(10),
        },
      }
    );

    sciChartSurface.xAxes.add(
      new LogarithmicAxis(wasmContext, {
        logBase: 2,
        labelFormat: ENumericFormat.SignificantFigures,
        autoRange: EAutoRange.Never,
        maxAutoTicks: 1,
        axisTitleStyle: { fontSize: 10 },
        drawMinorGridLines: false,
        drawMinorTickLines: true,
        drawMajorTickLines: false,
        growBy: new NumberRange(0.1, 0.2),
      })
    );

    sciChartSurface.yAxes.add(
      new NumericAxis(wasmContext, {
        autoRange: EAutoRange.Never,
        axisAlignment: EAxisAlignment.Left,
        visibleRange: new NumberRange(0, 25),
        drawMinorGridLines: false,
      })
    );

    crossSectionSliceSeries = new XyDataSeries(wasmContext);
    sciChartSurface.renderableSeries.add(
      new FastMountainRenderableSeries(wasmContext, {
        stroke: "#64BAE4",
        strokeThickness: 3,
        strokeDashArray: [2, 2],
        fillLinearGradient: new GradientParams(
          new Point(0, 0),
          new Point(0, 1),
          [
            { color: "#64BAE477", offset: 0 },
            { color: "#64BAE433", offset: 1 },
          ]
        ),
        dataSeries: crossSectionSliceSeries,
        zeroLineY: -999,
      })
    );

    sciChartSurface.zoomExtentsX();

    return { sciChartSurface };
  };

  const configureAfterInit = (onClick: any) => {
    // Link interactions together
    // if (mainChartSelectionModifier) {
    mainChartSelectionModifier.selectionChanged.subscribe((args) => {
      const selectedSeries = args?.selectedSeries[0]?.dataSeries;
      if (selectedSeries) {
        crossSectionSelectedSeries.dataSeries = selectedSeries;
      }
      crossSectionLegendModifier.isEnabled = true;
      crossSectionLegendModifier.sciChartLegend?.invalidateLegend();
    });
    mainChartSelectionModifier.hoverChanged.subscribe((args) => {
      const hoveredSeries = args?.hoveredSeries[0]?.dataSeries;
      if (hoveredSeries) {
        crossSectionHoveredSeries.dataSeries = hoveredSeries;
      }
      crossSectionLegendModifier.sciChartLegend?.invalidateLegend();
    });
    // }

    // Add a function to update drawing the cross-selection when the drag annotation is dragged
    const updateDragAnnotation = () => {
      // Don't allow to drag vertically, only horizontal
      dragMeAnnotation.y1 = 1;

      // Find the index to the x-values that the axis marker is on
      // Note you could just loop getNativeXValues() here but the wasmContext.NumberUtil function does it for you
      const dataIndex =
        mainChartSurface.webAssemblyContext2D.NumberUtil.FindIndex(
          mainChartSurface.renderableSeries
            .get(0)
            .dataSeries.getNativeXValues(),
          dragMeAnnotation.x1,
          mainChartSurface.webAssemblyContext2D.SCRTFindIndexSearchMode.Nearest,
          true
        );

      crossSectionPaletteProvider.selectedIndex = dataIndex;
      crossSectionPaletteProvider.shouldUpdate = true;
      mainChartSurface.invalidateElement();
      crossSectionSliceSeries.clear();
      for (let i = 0; i < mainChartSurface.renderableSeries.size(); i++) {
        crossSectionSliceSeries.append(
          i,
          mainChartSurface.renderableSeries
            .get(i)
            .dataSeries.getNativeYValues()
            .get(dataIndex)
        );
      }
    };

    // Run it once
    updateDragAnnotation();

    //Run it when user drags the annotation
    dragMeAnnotation.dragDelta.subscribe(
      (args: AnnotationDragDeltaEventArgs | undefined) => {
        updateDragAnnotation();
      }
    );

    mainChartSurface.renderableSeries.get(0).isSelected = true;

    promptAnnotation.clicked.subscribe(
      (args: AnnotationClickEventArgs | undefined) => {
        onClick();
      }
    );
  };

  const takeChartScreenshot = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toJpeg(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "chart.jpg";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  return (
    <div className="col-12 pb-5">
      <div className="row justify-content-between mb-4">
        <div className="col-4">
          <h3 className="mb-0 font-weight-bold">Espectro</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {data.length ? (
            <Fragment>
              <div
                ref={ref}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  height: "600px",
                }}
              >
                <div
                  id={divMainChartId}
                  style={{ flex: 1, flexBasis: "60%" }}
                />
                <div style={{ display: "flex", flex: 1, flexBasis: "40%" }}>
                  <div id={divCrossSection1} style={{ flex: 1 }} />
                  <div id={divCrossSection2} style={{ flex: 1 }} />
                </div>
              </div>
            </Fragment>
          ) : (
            <h5>Nenhum dado encontrado para este equipamento</h5>
          )}
        </div>
      </div>
      {isLoading && <Loading />}
    </div>
  );
};

export { WaterfallChart };
