import { FC, useEffect, useState, useRef } from "react";
import * as Plot from "@observablehq/plot";
import { LinearChartModel } from "../../core/_models";
import { useTemperatureRequest } from "../../core/TemperatureRequestProvider";

type Props = {
  data: LinearChartModel[];
  isLoadingData: boolean;
  nodeId: string;
};

const MultilineChart: FC<Props> = ({ data, isLoadingData, nodeId }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data === undefined) return;
    const plot = Plot.plot({
      style: "overflow: visible;",
      y: { grid: true },
      marks: [
        Plot.ruleY([0]),
        Plot.lineY(data, {
          x: (d) => new Date(d.date),
          y: "value",
          z: "data_type",
          stroke: "data_type",
        }),
      ],
    });
    if (containerRef.current) {
      // @ts-ignore
      containerRef.current.append(plot);
    }
    return () => plot.remove();
  }, [data]);

  return (
    <>
      {!isLoadingData ? (
        <div ref={containerRef} id={nodeId}></div>
      ) : (
        <p>Carregando...</p>
      )}
    </>
  );
};

export { MultilineChart };
