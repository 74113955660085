import { useEffect, Fragment } from "react";
//@ts-ignore
import { FFT } from "dsp.js";
import {
  CameraController,
  MouseWheelZoomModifier3D,
  NumericAxis3D,
  OrbitModifier3D,
  parseColorToUIntArgb,
  PointLineRenderableSeries3D,
  ResetCamera3DModifier,
  SciChart3DSurface,
  TGradientStop,
  Vector3,
  XyzDataSeries3D,
  TextLabelProvider,
  TooltipModifier3D,
  EllipsePointMarker3D,
} from "scichart";
import { appTheme } from "./components/scichart/theme";
import {
  useSpectraResponseData,
  useSpectraResponseLoading,
} from "../core/SpectraResponseProvider";

const divElementId = "chart3d";

type TMetadata = {
  vertexColor: number;
  pointScale: number;
};

let mainChartSurface: SciChart3DSurface;

const Waterfall3dChart = () => {
  const data = useSpectraResponseData();
  const isLoading = useSpectraResponseLoading();
  // const [metadataResult, setMetadataResult] = useState<TMetadata[]>([])
  var metadataResult: TMetadata[] = [];

  const createSpectralData = async (serie: string, i: number) => {
    //@ts-ignore
    const timeData: number[] = data[i].capture[serie];

    const sampleRate = 16000; // Taxa de amostragem em Hz
    const maxFrequency = 6000; // Frequência máxima a ser mostrada

    // Criando o objeto FFT
    const fft = new FFT(timeData.length, sampleRate); // 44100 é a taxa de amostragem, ajuste conforme necessário

    // Executando a FFT nos valores
    fft.forward(timeData);

    // Extraindo as magnitudes das frequências
    const magnitudes = fft.spectrum; // Frequências associadas

    // Frequências associadas
    const frequencies = Array.from(
      { length: timeData.length / 2 },
      (_, i) => (i / timeData.length) * sampleRate
    );

    // Filtrar frequências para exibir apenas de 0 a 6000 Hz
    const filteredMagnitudes = magnitudes
      .slice(0, timeData.length / 2)
      .filter((_: any, i: any) => frequencies[i] <= maxFrequency);
    const filteredFrequencies = frequencies.filter(
      (freq) => freq <= maxFrequency
    );

    const xValues = filteredFrequencies;
    const yValues = filteredMagnitudes;

    return { xValues, yValues };
  };

  useEffect(() => {
    var element = document.getElementById(divElementId);
    if (data.length && element) {
      drawExample();
    }
  }, [data, isLoading]);

  // SCICHART CODE
  const drawExample = async () => {
    const { sciChart3DSurface, wasmContext } = await SciChart3DSurface.create(
      divElementId,
      {
        // disableAspect: true,
        theme: appTheme.SciChartJsTheme,
      }
    );

    mainChartSurface = sciChart3DSurface;

    mainChartSurface.invalidateElement();
    // sciChart3DSurface.worldDimensions = new Vector3(300, 100, 300);
    sciChart3DSurface.camera = new CameraController(wasmContext, {
      position: new Vector3(185, 229, -355),
      target: new Vector3(0, 50, 0),
    });

    sciChart3DSurface.chartModifiers.add(new MouseWheelZoomModifier3D());
    sciChart3DSurface.chartModifiers.add(new OrbitModifier3D());
    sciChart3DSurface.chartModifiers.add(new ResetCamera3DModifier());

    // Create the labelProvider
    const labelProvider = new TextLabelProvider({
      // When passed as an array, labels will be used in order
      labels: Array.from(data).map((d) =>
        new Date(d.capture_date).toLocaleDateString("pt-BR")
      ),
    });

    sciChart3DSurface.xAxis = new NumericAxis3D(wasmContext, {
      axisTitle: "Frequência (Hz)",
      drawMinorGridLines: false,
      drawMajorGridLines: false,
      tickLabelsOffset: 20,
    });
    sciChart3DSurface.yAxis = new NumericAxis3D(wasmContext, {
      axisTitle: "Amplitude",
      drawMinorGridLines: false,
      drawMajorGridLines: false,
      tickLabelsOffset: 20,
    });
    sciChart3DSurface.zAxis = new NumericAxis3D(wasmContext, {
      axisTitle: "Capturas",
      drawMinorGridLines: false,
      drawMajorGridLines: false,
      tickLabelsOffset: 20,
    });

    for (let i = 0; i < data.length; i++) {
      // Create some data for the example
      // xValues are frequency values (Hz)
      // yValues are heights or magnitude

      const { xValues, yValues } = await createSpectralData("horizontal", i);
      // console.log(createSpectralDataTest(i))
      // zValues are the 3rd dimension where we will spread out our series in time
      const zValues = Array.from({ length: xValues.length }).map((_) => i * 2);

      // Metadata in scichart.js 3D controls color 3D line series. It can also hold additional optional properties
      // Below we format the data for yValues into metadata colour coded and scaled depending on the value
      const metadata = formatMetadata(yValues, [
        { offset: 1, color: appTheme.VividPink },
        { offset: 0.9, color: appTheme.VividOrange },
        { offset: 0.7, color: appTheme.MutedRed },
        { offset: 0.5, color: appTheme.VividGreen },
        { offset: 0.3, color: appTheme.VividSkyBlue },
        { offset: 0.2, color: appTheme.Indigo },
        { offset: 0, color: appTheme.DarkIndigo },
      ]);

      // Add a 3D Point-Line chart
      var dataSeries = new XyzDataSeries3D(wasmContext, {
        xValues,
        yValues,
        zValues,
        metadata,
      });

      sciChart3DSurface.renderableSeries.add(
        new PointLineRenderableSeries3D(wasmContext, {
          dataSeries: dataSeries,
          strokeThickness: 4,
          opacity: 0.5,
          pointMarker: new EllipsePointMarker3D(wasmContext, { size: 3 }),
        })
      );
    }

    // Declare a tooltip and add to the chart
    const tooltipModifier = new TooltipModifier3D({
      crosshairStroke: "#83D2F5",
      crosshairStrokeThickness: 3,
      tooltipContainerBackground: "#537ABD",
      tooltipTextStroke: "White",
      tooltipLegendOffsetX: 10,
      tooltipLegendOffsetY: 10,
    });

    sciChart3DSurface.chartModifiers.add(tooltipModifier);

    // Customize the tooltip content
    tooltipModifier.tooltipDataTemplate = (seriesInfo, svgAnnotation) => {
      // Create an array to hold strings (lines) to show in the tooltip
      const valuesWithLabels = [];
      if (seriesInfo && seriesInfo.isHit) {
        // You can access the renderableSeries which was hit via the seriesInfo
        const renderableSeries = seriesInfo.renderableSeries;
        // And the parent Chart from that
        const parentSurface = renderableSeries.parentSurface;

        // Push lines to the array to display in the tooltip
        valuesWithLabels.push(
          ` ${parentSurface.xAxis.axisTitle}: ${seriesInfo.xValue.toFixed(2)}`
        );
        valuesWithLabels.push(
          ` ${parentSurface.yAxis.axisTitle}: ${seriesInfo.yValue.toFixed(2)}`
        );
        valuesWithLabels.push(
          ` ${parentSurface.zAxis.axisTitle}: ${seriesInfo.zValue.toFixed(2)}`
        );
      }
      return valuesWithLabels;
    };
  };

  function formatMetadata(
    valuesArray: number[],
    gradientStops: TGradientStop[]
  ): TMetadata[] {
    const low = Math.min(...valuesArray);
    const high = Math.max(...valuesArray);
    metadataResult.length = 0;

    const sGradientStops = gradientStops.sort((a, b) =>
      a.offset > b.offset ? 1 : -1
    );
    // Compute a scaling factor from 0...1 where values in valuesArray at the lower end correspond to 0 and
    // values at the higher end correspond to 1
    valuesArray.map((x, i) => {
      // scale from 0..1 for the values
      var valueScale: number = (x - low) / (high - low);
      if (isNaN(valueScale)) {
        valueScale = 0.1;
      }
      // Find the nearest gradient stop index
      const index = sGradientStops.findIndex((gs) => gs.offset >= valueScale);
      // const nextIndex = Math.min(index + 1, sGradientStops.length - 1);
      // work out the colour of this point
      const color1: number = parseColorToUIntArgb(
        sGradientStops[index < 0 ? 0 : index].color
      );
      // const color2 = parseColorToUIntArgb(sGradientStops[nextIndex].color);
      // const ratio = (valueScale - sGradientStops[index].offset) / (sGradientStops[nextIndex].offset - sGradientStops[index].offset)
      // const colorScale = uintArgbColorLerp(color1, color2, ratio)
      // console.log(`valueScale ${valueScale} low ${sGradientStops[index].offset} high ${sGradientStops[nextIndex].offset} ratio ${ratio}`);
      const data: TMetadata = {
        pointScale: 0.1 + valueScale,
        vertexColor: color1,
      };

      metadataResult.push(data);

      return data;
    });

    return metadataResult;
  }

  return (
    <div className="col-12 pb-5">
      <div className="row justify-content-between mb-4">
        <div className="col-4">
          <h3 className="mb-0 font-weight-bold">Gráfico em cascata</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Fragment>
            {isLoading ? (
              <p>Carregando</p>
            ) : (
              <div style={{ height: "100%", aspectRatio: 2.3333 }}>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <div
                    id={divElementId}
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                    }}
                  ></div>
                </div>
              </div>
            )}
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export { Waterfall3dChart };
