import { useState, useEffect } from "react";
import { MultilineChart } from "./components/MultilineChart";
import { useVelocityRequest } from "../core/VelocityRequestProvider";
import {
  useVelocityResponseData,
  useVelocityResponseLoading,
} from "../core/VelocityResponseProvider";

const VelocityChart = () => {
  const { updateState } = useVelocityRequest();
  const velocity = useVelocityResponseData();
  const isVelocityLoading = useVelocityResponseLoading();
  const [advancedFilter, setAdvancedFilter] = useState<boolean>(false);

  useEffect(() => {
    getVelocityData();
  }, []);

  const getVelocityData = () => {
    updateState({ filter: { data_type: "rms_velocity", date: "10" } });
  };

  return (
    <div className="col-12">
      <div className="row mb-5">
        <div className="col-4">
          <h4 className="mb-3 font-weight-bold">Gráfico de velocidade</h4>
        </div>
        <div className="col-12">
          <div className="row mb-4">
            <div className="col-md-2">
              <label
                htmlFor="search-linear-temperature"
                className="form-label mb-1"
              >
                <small>Filtro</small>
              </label>
              <select
                className="form-select"
                name="search-linear-temperature"
                id="search-linear-temperature"
                onChange={(e) => {
                  if (e.target.value != "personalizado") {
                    setAdvancedFilter(false);
                    updateState({
                      filter: {
                        date: e.target.value,
                        data_type: "rms_velocity",
                      },
                    });
                  } else {
                    setAdvancedFilter(true);
                  }
                }}
              >
                <option value="">Selecione um filtro</option>
                <option value="5">Últimos 5 dias</option>
                <option value="10">Últimos 10 dias</option>
                <option value="20">Últimos 20 dias</option>
                <option value="30">Últimos 30 dias</option>
                <option value="personalizado">Personalizado</option>
              </select>
            </div>
            {advancedFilter && (
              <div className="col-md-4">
                <div className="d-flex align-items-end">
                  <div className="me-2">
                    <label htmlFor="start_date" className="form-label mb-1">
                      Início
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="start_date_velocity"
                      id="start_date_velocity"
                    />
                  </div>
                  <div className="me-2">
                    <label htmlFor="end_date" className="form-label mb-1">
                      Fim
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="end_date_velocity"
                      id="end_date_velocity"
                    />
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      updateState({
                        filter: {
                          start_date: (
                            document.querySelector(
                              "input#start_date_velocity"
                            ) as HTMLInputElement
                          ).value,
                          end_date: (
                            document.querySelector(
                              "input#end_date_velocity"
                            ) as HTMLInputElement
                          ).value,
                          data_type: "rms_velocity",
                        },
                      })
                    }
                  >
                    Filtrar
                  </button>
                </div>
              </div>
            )}
          </div>
          <MultilineChart
            nodeId="velocity_chart"
            data={velocity}
            isLoadingData={isVelocityLoading}
          />
        </div>
      </div>
    </div>
  );
};

export { VelocityChart };
