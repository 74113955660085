import { FC, useEffect, useState } from "react";
import * as d3 from "d3";
import { LinearChartModel } from "../../core/_models";
import { useTemperatureRequest } from "../../core/TemperatureRequestProvider";
import { useTemperatureResponseData, useTemperatureResponseLoading } from "../../core/TemperatureResponseProvider";

type Props = {
  // data: LinearChartModel[];
  // isLoadingData: boolean;
  nodeId: string;
};

const LinearTemperature: FC<Props> = ({ nodeId }) => {
  const { updateState } = useTemperatureRequest();
  const [advancedFilter, setAdvancedFilter] = useState<boolean>(false);
  const data = useTemperatureResponseData();
  const isTemperatureLoading = useTemperatureResponseLoading();

  useEffect(() => {
    getTemperatureData();
  }, []);

  const getTemperatureData = () => {
    updateState({ filter: { date: "10" } });
  };
  //d3 line chart
  const createGraph = async () => {
    if (data.length) {
      const marginTop = 20;
      const marginRight = 30;
      const marginBottom = 30;
      const marginLeft = 40;
      // read from csv and format variables
      // let data = await d3.csv(
      //   "https://raw.githubusercontent.com/holtzy/data_to_viz/master/Example_dataset/3_TwoNumOrdered_comma.csv"
      // );
      let parseTime = d3.timeParse("%Y-%m-%d");
      data.forEach((d) => {
        //@ts-ignore
        d.date = parseTime(d.date);
        d.value = +d.value;
      });
      // set the dimensions and margins of the graph
      var margin = { top: 20, right: 20, bottom: 50, left: 70 },
        width = 960 - margin.left - margin.right,
        height = 500 - margin.top - margin.bottom;
      // append the svg object to the body of the page
      var svg = d3
        .select(`#${nodeId}`)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);
      svg.exit().remove();
      // Add X axis and Y axis
      const x = d3
        .scaleUtc()
        // @ts-ignore
        .domain(d3.extent(data, (d) => d.date))
        .range([marginLeft, width - marginRight]);
      const y = d3
        .scaleLinear()
        // @ts-ignore
        .domain(d3.extent(data, (d) => d.value))
        .nice()
        .range([height - marginBottom, marginTop]);

      // const defs = svg.append('defs');

      svg
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(x));
      svg.append("g").call(d3.axisLeft(y));

      // add the Line
      const valueLine = d3
        .line()
        .curve(d3.curveStep)
        // @ts-ignore
        .defined((d) => !isNaN(d.value))
        // @ts-ignore
        .x((d) => x(d.date))
        // @ts-ignore
        .y((d) => y(d.value));
      const color = d3.scaleSequential(y.domain(), d3.interpolateTurbo);
      svg
        .append("linearGradient")
        .attr("id", "line-gradient-temperature")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", 0)
        .attr("y1", height - marginBottom)
        .attr("x2", 0)
        .attr("y2", marginTop)
        .selectAll("stop")
        .data(d3.ticks(0, 1, 10))
        .join("stop")
        .attr("offset", (d) => d)
        .attr("stop-color", color.interpolator());
      svg
        .append("path")
        .data([data])
        .attr("class", "line")
        .attr("fill", "none")
        .attr("stroke", "url(#line-gradient-temperature)")
        .attr("stroke-width", 1.5)
        // @ts-ignore
        .attr("d", valueLine);
    }
  };

  useEffect(() => {
    createGraph();
  }, [data]);

  return (
    <>
      <div className="row">
        <div className="col-md-2">
          <label
            htmlFor="search-linear-temperature"
            className="form-label mb-1"
          >
            <small>Filtro</small>
          </label>
          <select
            className="form-select"
            name="search-linear-temperature"
            id="search-linear-temperature"
            onChange={(e) => {
              if (e.target.value != "personalizado") {
                setAdvancedFilter(false);
                updateState({ filter: { date: e.target.value } });
              } else {
                setAdvancedFilter(true);
              }
            }}
          >
            <option value="">Selecione um filtro</option>
            <option value="5">Últimos 5 dias</option>
            <option value="10">Últimos 10 dias</option>
            <option value="20">Últimos 20 dias</option>
            <option value="30">Últimos 30 dias</option>
            <option value="personalizado">Personalizado</option>
          </select>
        </div>
        {advancedFilter && (
          <div className="col-md-4">
            <div className="d-flex align-items-end">
              <div className="me-2">
                <label htmlFor="start_date" className="form-label mb-1">
                  Início
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="start_date"
                  id="start_date"
                />
              </div>
              <div className="me-2">
                <label htmlFor="end_date" className="form-label mb-1">
                  Fim
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="end_date"
                  id="end_date"
                />
              </div>
              <button
                className="btn btn-primary"
                onClick={() =>
                  updateState({
                    filter: {
                      start_date: (
                        document.querySelector(
                          "input#start_date"
                        ) as HTMLInputElement
                      ).value,
                      end_date: (
                        document.querySelector(
                          "input#end_date"
                        ) as HTMLInputElement
                      ).value,
                    },
                  })
                }
              >
                Filtrar
              </button>
            </div>
          </div>
        )}
      </div>
      {!isTemperatureLoading ? (
        <>
          <div id={nodeId}></div>
        </>
      ) : (
        <p>Carregando...</p>
      )}
    </>
  );
};

export { LinearTemperature };
