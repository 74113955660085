import axios, { AxiosResponse } from "axios";
import { SpectraCaptura, LinearChartModel, SpectraList, ResponseCaptureDataSpectra } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;
const EQUIPMENT_URL = `${API_URL}/equipment`;
const SPECTRA_URL = `${API_URL}/spectrum-data-capture`;

const getCaptures = (
  equipmentId: number,
  search: string
): Promise<LinearChartModel[]> => {
  return axios
    .get(`${EQUIPMENT_URL}/${equipmentId}/equipment-data-capture?${search}`)
    .then((d: AxiosResponse<LinearChartModel[]>) => d.data);
};

const getTemperature = (
  equipmentId: number,
  search: string
): Promise<LinearChartModel[]> => {
  return axios
    .get(`${EQUIPMENT_URL}/${equipmentId}/equipment-temperature?${search}`)
    .then((d: AxiosResponse<LinearChartModel[]>) => d.data);
};

const getSpectraCaptures = (
  equipmentId: number,
  search: string
): Promise<SpectraCaptura[]> => {
  return axios
    .get(`${SPECTRA_URL}/${equipmentId}/equipment-spectra?${search}`)
    .then((d: AxiosResponse<SpectraCaptura[]>) => d.data);
};

const getSpectraCapturesList = (
  equipmentId: number
): Promise<SpectraList[]> => {
  return axios
    .get(`${SPECTRA_URL}/${equipmentId}/list`)
    .then((d: AxiosResponse<SpectraList[]>) => d.data);
};

const captureSpectraData = (
  equipmentId: number
): Promise<ResponseCaptureDataSpectra> => {
  return axios
    .post(`${SPECTRA_URL}/${equipmentId}/capture-spectrum`)
    .then((d: AxiosResponse<ResponseCaptureDataSpectra>) => d.data);
};

export {
  getCaptures,
  getSpectraCaptures,
  getTemperature,
  getSpectraCapturesList,
  captureSpectraData
};
