import { createContext } from "react";
import qs from 'qs'
import { QueryResponseContextProps, Search, SpectraQueryResponseContextProps } from "./Model";

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState);
}

function createSpectraResponseContext<T>(initialState: SpectraQueryResponseContextProps<T>) {
  return createContext(initialState);
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== "";
}

// Example: page=1&per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: Search): string {
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ["search"], skipNulls: true })
    : "";

    const filter = state.filter
    ? Object.entries(state.filter as Object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `filter_${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  return [search, filter]
    .filter((f) => f)
    .join("&")
    .toLowerCase();
}

export { createResponseContext, isNotEmpty, stringifyRequestQuery, createSpectraResponseContext };
