import { Routes, Outlet, Route } from "react-router-dom";
import { BearingsList } from "./components/bearings-list/BearingsList";
import { BearingsEdit } from "./components/bearings-edit/BearingsEdit";
import { useAuth } from "../../modules/auth";

const BearingsPage = () => {
  const { currentUser } = useAuth();
  const bearingPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 7;
  })[0];

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="/" element={<BearingsList />} />
        {(!!bearingPermissions?.create ||
          currentUser?.profile.name === "administrador") && (
          <Route path="/new" element={<BearingsEdit />} />
        )}
        {(!!bearingPermissions?.update ||
          currentUser?.profile.name === "administrador") && (
          <Route path=":bearingId" element={<BearingsEdit />} />
        )}
      </Route>
    </Routes>
  );
};

export default BearingsPage;
