import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import { getSpectraCapturesList } from "../../core/_requests";
import { useSpectraRequest } from "../../core/SpectraRequestProvider";

type SpectraData = {
  id: number;
  capture_date: string;
};

const SpectraConfig = () => {
  const { equipmentId } = useParams();
  const { updateState } = useSpectraRequest();
  const [selectedCapturas, setSelectedCapturas] = useState<Array<number>>();
  const columns = [
    {
      name: "Data da captura",
      selector: (row: any) => row.capture_date!,
      sortable: true,
    },
  ];

  const { isFetching, refetch, data } = useQuery(
    `SPECTRA_LIST`,
    () => {
      return getSpectraCapturesList(parseInt(equipmentId!));
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const customStyles = {
    rows: {
      style: {
        border: "none !important",
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F9FAFC",
        },
        fontSize: "14px",
        color: "#212529",
      },
    },

    headRow: {
      style: {
        backgroundColor: "#F9FAFC",
        fontWeight: "bold",
        border: "0px",
        fontSize: "15px",
        color: "#212529",
      },
    },
  };

  const handleRowSelect = (selectedRows: SpectraData[]) => {
    const selectedIds = selectedRows.map((row) => row.id);
    setSelectedCapturas(selectedIds);
  };

  return (
    <>
      <div
        className="modal fade"
        id="spectra-settings-modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Configurações do gráfico em cascata
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <DataTable
                selectableRows
                onSelectedRowsChange={(data) =>
                  handleRowSelect(data.selectedRows)
                }
                columns={columns}
                data={data!}
                customStyles={customStyles}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Fechar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() =>
                  updateState({
                    filter: {
                      capturas: selectedCapturas!,
                    },
                  })
                }
              >
                Filtrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { SpectraConfig };
