import { Suspense } from "react";
import { AuthInit } from "./modules/auth";
import { Outlet } from "react-router-dom";
import { LayoutSplashScreen } from "../theme/layout/core/ThemeSplashScreen";
import { LayoutProvider } from "../theme/layout/core/_LayoutProvider";

function App() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <LayoutProvider>
        <AuthInit>
          <Outlet />
        </AuthInit>
      </LayoutProvider>
    </Suspense>
  );
}

export default App;
