import {Link} from 'react-router-dom'
import { toAbsoluteUrl } from '../../../helpers/AssetsHelpers'

const SidebarLogo = () => {
  
  return (
    <div className='app-sidebar-logo'>
      <Link to='/dashboard'>
        <img
          alt='Acoplast'
          src={toAbsoluteUrl('/logo_acoplast.png')}
          className='h-45px app-sidebar-logo-minimize'
        />
      </Link>
    </div>
  )
}

export {SidebarLogo}
