import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../theme/layout/MasterLayout";
import { useAuth } from "../modules/auth";
import DashboardPage from "../pages/dashboard/DashboardPage";
import UsersPage from "../pages/users/UsersPage";
import ProfilesPage from "../pages/profiles/ProfilesPage";
import ClientsPage from "../pages/clients/ClientsPage";
import AreasPage from "../pages/areas/AreasPage";
import CellsPage from "../pages/cells/CellsPage";
import EquipmentPage from "../pages/equipment/EquipmentPage";
import BearingsPage from "../pages/bearings/BearingsPage";
import AlarmParameterizationsPage from "../pages/alarm-parameterizations/AlarmParameterizationsPage";
import SpectraPage from "../pages/spectra/SpectraPage";
import SettingsPage from "../pages/settings/SettingsPage";

const PrivateRoutes = () => {
  const { currentUser } = useAuth();
  const userPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 1;
  })[0];
  const profilePermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 2;
  })[0];
  const clientPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 3;
  })[0];
  const areaPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 4;
  })[0];
  const cellPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 5;
  })[0];
  const equipmentPermissions = currentUser?.permissions?.filter(
    (permission) => {
      return permission.module_id === 6;
    }
  )[0];
  const bearingPermissions = currentUser?.permissions?.filter((permission) => {
    return permission.module_id === 7;
  })[0];
  const parameterizationPermissions = currentUser?.permissions?.filter(
    (permission) => {
      return permission.module_id === 8;
    }
  )[0];

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardPage />} />
        {/* Lazy Modules */}

        {currentUser?.profile?.name === "administrador" ? (
          <>
            <Route path="users/*" element={<UsersPage />} />
            <Route path="profiles/*" element={<ProfilesPage />} />
            <Route path="clients/*" element={<ClientsPage />} />
            <Route path="areas/*" element={<AreasPage />} />
            <Route path="cells/*" element={<CellsPage />} />
            <Route path="equipment/*" element={<EquipmentPage />} />
            <Route path="bearings/*" element={<BearingsPage />} />
            <Route
              path="alarm-parameterizations/*"
              element={<AlarmParameterizationsPage />}
            />
            <Route
              path="spetrum/*"
              element={<SpectraPage />}
            />
          </>
        ) : (
          <>
            userPermissions?.read && (
            <Route path="users/*" element={<UsersPage />} />)
            profilePermissions?.read && (
            <Route path="profiles/*" element={<ProfilesPage />} />)
            clientPermissions?.read && (
            <Route path="clients/*" element={<ClientsPage />} />)
            areaPermissions?.read && (
            <Route path="areas/*" element={<AreasPage />} />)
            cellPermissions?.read && (
            <Route path="cells/*" element={<CellsPage />} />)
            equipmentPermissions?.read && (
            <Route path="equipment/*" element={<EquipmentPage />} />)
            bearingPermissions?.read && (
            <Route path="bearings/*" element={<BearingsPage />} />)
            parameterizationPermissions?.read && (
            <Route
              path="alarm-parameterizations/*"
              element={<AlarmParameterizationsPage />}
            />
            <Route
              path="spetrum/*"
              element={<SpectraPage />}
            />
            )
          </>
        )}
        <Route
          path="settings/*"
          element={<SettingsPage />}
        />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
