export type ProfilePermission = {
    id?: number;
    module_id?: number;
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;
};

export type Profile = {
    id?: number,
    name?: string,
    user_permissions?: ProfilePermission[]
}

export type Module = {
    id?: number,
    name?: string
}

export type ResponseProfile = {
    message: string
}

export const ProfileInitialValue: Profile = {
    id: 0,
    name: '',
    user_permissions: []
}