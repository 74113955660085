import axios, { AxiosResponse } from "axios";
import {
  AlarmParameterization,
  ResponseAlarmParameterization,
} from "./_models";

const API_URL = process.env.REACT_APP_API_URL;
const ALARM_PARAMETERIZATION_URL = `${API_URL}/alarm-parameterizations`;

const getAlarmParameterizations = (
  query: string = ""
): Promise<Array<AlarmParameterization>> => {
  return axios
    .get(`${ALARM_PARAMETERIZATION_URL}?${query}`)
    .then((d: AxiosResponse<Array<AlarmParameterization>>) => d.data);
};

const getAlarmParameterizationById = (
  id: string
): Promise<AlarmParameterization> => {
  return axios
    .get(`${ALARM_PARAMETERIZATION_URL}/${id}`)
    .then((response: AxiosResponse<AlarmParameterization>) => response.data);
};

const createAlarmParameterization = (
  alarmParameterization: AlarmParameterization
): Promise<ResponseAlarmParameterization> => {
  return axios
    .post(`${ALARM_PARAMETERIZATION_URL}`, alarmParameterization)
    .then(
      (response: AxiosResponse<ResponseAlarmParameterization>) => response.data
    );
};

const updateAlarmParameterization = (
  alarmParameterization: AlarmParameterization
): Promise<ResponseAlarmParameterization> => {
  return axios
    .put(
      `${ALARM_PARAMETERIZATION_URL}/${alarmParameterization.id}`,
      alarmParameterization
    )
    .then(
      (response: AxiosResponse<ResponseAlarmParameterization>) => response.data
    );
};

const deletealarmParameterization = (
  alarmParameterizationId: number
): Promise<ResponseAlarmParameterization> => {
  return axios
    .delete(`${ALARM_PARAMETERIZATION_URL}/${alarmParameterizationId}`)
    .then(
      (response: AxiosResponse<ResponseAlarmParameterization>) => response.data
    );
};

export {
  getAlarmParameterizations,
  getAlarmParameterizationById,
  createAlarmParameterization,
  updateAlarmParameterization,
  deletealarmParameterization,
};
