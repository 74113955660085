import { useState } from "react";
import { Link } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getAlarmParameterizations,
  deletealarmParameterization,
} from "../core/_requests";
import { AlarmParameterization } from "../core/_models";
import { AlertBoostrapButtons } from "../../../../../theme/widgets/alerts/AlertBoostrapButtons";
import { Toast } from "../../../../../theme/widgets/alerts/Toast";
import { useAuth } from "../../../../modules/auth";

const AlarmParameterizationsList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [parameterizations, setParameterizations] = useState<
    AlarmParameterization[]
  >([]);
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  const parameterizationPermissions = currentUser?.permissions?.filter(
    (permission) => {
      return permission.module_id === 8;
    }
  )[0];

  const columns: TableColumn<AlarmParameterization>[] = [
    {
      name: "Limite mínimo de alarme",
      selector: (row: AlarmParameterization) => row.alarm_limits_min!,
      sortable: true,
    },
    {
      name: "Limite máximo de alarme",
      selector: (row: AlarmParameterization) => row.alarm_limits_max!,
      sortable: true,
    },
    {
      name: "Descrição",
      selector: (row: AlarmParameterization) => row.description!,
    },
    {
      cell: (row: AlarmParameterization) => (
        <>
          {(!!parameterizationPermissions?.update ||
            currentUser?.profile.name === "administrador") && (
            <Link
              to={`/alarm-parameterizations/${row.id}`}
              className="btn btn-success btn-sm me-2"
              title="Editar"
            >
              <i className="bi bi-pencil"></i>
            </Link>
          )}
          {(!!parameterizationPermissions?.delete ||
            currentUser?.profile.name === "administrador") && (
            <button
              onClick={() => confirmParameterizationDelete(row.id!)}
              className="btn btn-danger btn-sm"
              title="Remover"
            >
              <i className="bi bi-trash"></i>
            </button>
          )}
        </>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        border: "none !important",
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F9FAFC",
        },
        fontSize: "14px",
        color: "#212529",
      },
    },

    headRow: {
      style: {
        backgroundColor: "#F9FAFC",
        fontWeight: "bold",
        border: "0px",
        fontSize: "15px",
        color: "#212529",
      },
    },
  };

  const { isFetching, refetch, data } = useQuery(
    `ALARM_PARAMETERIZATIONS_LIST`,
    () => {
      return getAlarmParameterizations();
    },
    {
      onSuccess: (data) => {
        setParameterizations(data);
      },
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const confirmParameterizationDelete = async (parameterizationId: number) => {
    AlertBoostrapButtons.fire({
      title: "Você tem certeza que deseja remover essa parametrização?",
      html: `Essa ação não poderá ser desfeita`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Excluir",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        return await deleteItem.mutateAsync(parameterizationId);
      }
    });
  };

  const deleteItem = useMutation(
    (id: number) => deletealarmParameterization(id),
    {
      onSuccess: (response) => {
        Toast.fire({
          icon: "success",
          title: response.message,
          customClass: {
            popup: "bg-success",
            title: "text-light",
          },
        });
        queryClient.invalidateQueries(["ALARM_PARAMETERIZATIONS_LIST"]);
      },
    }
  );

  const filteredParameterizations = parameterizations.filter(
    (parameterization) =>
      (parameterization?.alarm_limits_min ?? 0)
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      (parameterization?.alarm_limits_max ?? 0)
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      (parameterization?.description ?? 0)
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="row justify-content-between align-items-center border border-color-ccc shadow-sm rounded bg-white col-12 mx-auto mt-3 py-4 col-lg-11">
        <div className="col-12 d-flex justify-content-between gap-2 flex-column flex-lg-row align-items-lg-center ">
          <div className="col-4">
            <h2 className="mb-0 font-weight-bold">Parametrização de Alarmes</h2>
          </div>

          <div className="d-flex justify-content-between gap-3 flex-column flex-lg-row align-items-lg-center ">
            <div className="input-wrapper">
              <button className="icon">
                <i className="bi bi-search "></i>
              </button>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Pesquisar"
                className="search-input"
              />
            </div>

            {(!!parameterizationPermissions?.create ||
              currentUser?.profile.name === "administrador") && (
              <div className="row">
                <div className="col-12">
                  <Link
                    to={"/alarm-parameterizations/new"}
                    className="btn btn-primary"
                  >
                    <i className="bi bi-plus-circle me-2"></i>
                    Nova parametrização
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row mt-3 m-0 p-0">
          <div className="col-12 m-0 p-0">
            {!isFetching ? (
              filteredParameterizations.length ? (
                <DataTable
                  columns={columns}
                  data={filteredParameterizations}
                  customStyles={customStyles}
                />
              ) : (
                <span className="p-3">Nenhum dado encontrado</span>
              )
            ) : (
              <span className="text-center p-3">Carregando...</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { AlarmParameterizationsList };
