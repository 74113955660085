import axios, { AxiosResponse } from "axios"
import { Area, ResponseArea } from "./_models"

const API_URL = process.env.REACT_APP_API_URL
const AREA_URL = `${API_URL}/areas`

const getAreas = (): Promise<Array<Area>> => {
    return axios.get(AREA_URL).then((d: AxiosResponse<Array<Area>>) => d.data)
}

const getAreaById = (id: string): Promise<Area> => {
  return axios
    .get(`${AREA_URL}/${id}`)
    .then((response: AxiosResponse<Area>) => response.data)
}

const createArea = (area: Area): Promise<ResponseArea> => {
    return axios
      .post(`${AREA_URL}`, area)
      .then((response: AxiosResponse<ResponseArea>) => response.data)
}

const updateArea = (area: Area): Promise<ResponseArea> => {
    return axios
      .put(`${AREA_URL}/${area.id}`, area)
      .then((response: AxiosResponse<ResponseArea>) => response.data)
}

const deleteArea = (areaId: number): Promise<ResponseArea> => {
  return axios.delete(`${AREA_URL}/${areaId}`)
  .then((response: AxiosResponse<ResponseArea>) => response.data)
}

export {getAreas, getAreaById, createArea, updateArea, deleteArea}
