/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  WithChildren,
  initialQueryResponse,
} from "../../../../../theme/helpers/Model";
import {
  createResponseContext,
  stringifyRequestQuery,
  createSpectraResponseContext,
  isNotEmpty,
} from "../../../../../theme/helpers/CrudHelpers";
import { getSpectraCaptures } from "./_requests";
import { LinearChartModel, SpectraCaptura } from "./_models";
import { useSpectraRequest } from "./SpectraRequestProvider";

const SpectraResponseContext =
  createSpectraResponseContext<SpectraCaptura>(initialQueryResponse);
const SpectraResponseProvider: FC<WithChildren> = ({ children }) => {
  const { equipmentId } = useParams();
  const { state } = useSpectraRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const enabledQuery: boolean = isNotEmpty(query);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `ACODATA_IOT_LIST_SPECTRA-${query}`,
    () => {
      return getSpectraCaptures(parseInt(equipmentId!), query);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
    }
  );

  return (
    <SpectraResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </SpectraResponseContext.Provider>
  );
};

const useSpectraResponse = () => useContext(SpectraResponseContext);

const useSpectraResponseData = () => {
  const { response } = useSpectraResponse();

  if (!response) {
    return [];
  }

  return response || [];
};

const useSpectraResponseLoading = (): boolean => {
  const { isLoading } = useSpectraResponse();
  return isLoading;
};

export {
  SpectraResponseProvider,
  useSpectraResponse,
  useSpectraResponseData,
  useSpectraResponseLoading,
};
