import axios, { AxiosResponse } from "axios"
import { Equipment, ResponseEquipment } from "./_models"

const API_URL = process.env.REACT_APP_API_URL
const EQUIPMENT_URL = `${API_URL}/equipment`

const getEquipment = (): Promise<Array<Equipment>> => {
    return axios.get(EQUIPMENT_URL).then((d: AxiosResponse<Array<Equipment>>) => d.data)
}

const getEquipmentById = (id: string): Promise<Equipment> => {
  return axios
    .get(`${EQUIPMENT_URL}/${id}`)
    .then((response: AxiosResponse<Equipment>) => response.data)
}

const createEquipment = (equipment: Equipment): Promise<ResponseEquipment> => {
    return axios
      .post(`${EQUIPMENT_URL}`, equipment)
      .then((response: AxiosResponse<ResponseEquipment>) => response.data)
}

const updateEquipment = (equipment: Equipment): Promise<ResponseEquipment> => {
    return axios
      .put(`${EQUIPMENT_URL}/${equipment.id}`, equipment)
      .then((response: AxiosResponse<ResponseEquipment>) => response.data)
}

const deleteEquipment = (equipmentId: number): Promise<ResponseEquipment> => {
  return axios.delete(`${EQUIPMENT_URL}/${equipmentId}`)
  .then((response: AxiosResponse<ResponseEquipment>) => response.data)
}

export {getEquipment, getEquipmentById, createEquipment, updateEquipment, deleteEquipment}
