export type Equipment = {
    id?: number,
    description?: string,
    tag?: string,
    area_id?: number,
    cell_id?: number,
    subset?: string,
    data_collection?: number,
    rotation_speed?: number,
    power?: number,
    frequency_inverter?: boolean,
    gear_z1?: string,
    gear_z2?: string,
    gear_z3?: string,
    gear_z4?: string,
    gear_z5?: string,
    gear_z6?: string,
    gear_z7?: string,
    gear_z8?: string,
    bearing_r1_id?: number,
    bearing_r2_id?: number,
    bearing_r3_id?: number,
    bearing_r4_id?: number,
    bearing_r5_id?: number,
    bearing_r6_id?: number,
    bearing_r7_id?: number,
    bearing_r8_id?: number,
    blades?: string,
    mac?: string,
    esn?: string
}

export type ResponseEquipment = {
    message: string
}

export const EquipmentInitialValue: Equipment = {
    id: 0,
    description: '',
    tag: '',
    area_id: undefined,
    cell_id: undefined,
    subset: '',
    data_collection: 5
}