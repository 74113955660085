import { FC, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { Setting, SettingInitialValue } from "../core/_models";
import { saveClientid } from "../core/_requests";
import { Toast } from "../../../../../theme/widgets/alerts/Toast";

type ProfilesFormProps = {
  setting: Setting;
};

type SelectType = {
  value: string;
  label: string;
};

const SettingsForm: FC<ProfilesFormProps> = ({ setting }) => {
  
  const [settingEdit] = useState<Setting>({
    ...setting,
    smartcelula_client_id: setting.smartcelula_client_id || SettingInitialValue.smartcelula_client_id
  });

  const save = useMutation((values: Setting) => saveClientid(values), {
    onSuccess: (response) => {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          popup: "bg-success",
          title: "text-light",
        },
      });
    },
    onError: (error: any) => {
      console.log(error);
      Toast.fire({
        icon: "error",
        iconHtml: "<span>!</span>",
        title: "Erro ao salvar ID do cliente.",
        customClass: {
          popup: "bg-danger",
          title: "text-light",
        },
      });
    },
  });

  const formik = useFormik({
    initialValues: settingEdit,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        return save.mutateAsync(values);
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <div className="row justify-content-between align-items-center border border-color-ccc shadow-sm rounded bg-white col-11 mx-auto py-3 mt-4">
        <div className="col-12 mb-lg-5 mb-2">
          <h2>Configurações</h2>
        </div>
        <div className="col-12">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="row gap-3 gap-lg-0">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="smartcelula_client_id">ID do Cliente</label>
                  <input
                    {...formik.getFieldProps("smartcelula_client_id")}
                    type="text"
                    name="smartcelula_client_id"
                    id="smartcelula_client_id"
                    className="form-control"
                  />
                  {formik.touched.smartcelula_client_id && formik.errors.smartcelula_client_id && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.smartcelula_client_id}</span>
                      </div>
                    </div>
                  )}
                  <small className="d-block">Chave para integração com IoT Smartcelula</small>
                </div>
              </div>
            </div>
            <div className="row  mt-5">
              <div className="col-12">
                <Link to={"/users"} className="btn btn-danger">
                  <i className="bi bi-arrow-left"></i> Voltar
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary ms-3"
                  disabled={
                    formik.isSubmitting || !formik.isValid || !formik.touched
                  }
                >
                  <span>Salvar</span>
                  {formik.isSubmitting && (
                    <div
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { SettingsForm };
