export type Area = {
    id?: number,
    area?: string
}

export type ResponseArea = {
    message: string
}

export const AreaInitialValue: Area = {
    id: 0,
    area: ''
}