import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import App from '../App'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { PrivateRoutes } from './PrivateRoutes'

const {PUBLIC_URL} = process.env

const AppRoutes = () => {
    const {currentUser} = useAuth()
    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App />}>
                <Route path='error/*' element={<ErrorsPage />} />
                <Route path='logout' element={<Logout />} />
                {currentUser ? (
                    <>
                        <Route path='/*' element={<PrivateRoutes />} />
                        <Route index element={<Navigate to='/dashboard' />} />
                    </>
                ) : (
                    <>
                    <Route path='auth/*' element={<AuthPage />} />
                    <Route path='*' element={<Navigate to='/auth' />} />
                    </>
                )}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export {AppRoutes}