import { FC, useState, useEffect, HTMLProps } from "react";

type Props = Omit<HTMLProps<HTMLInputElement>, "onChange"> & {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
};

const InputHour: FC<Props> = ({ onChange, value = "", ...props }) => {
  const [hourValue, setHourValue] = useState<string>(
    value
      .toString()
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1:$2")
      .replace(/(\d{2})(\d)/, "$1")
  );

  useEffect(() => {
    setHourValue(
      value
        .toString()
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1:$2")
        .replace(/(\d{2})(\d)/, "$1")
    );
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var value = event.target.value;

    value = value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1:$2")
      .replace(/(\d{2})(\d)/, "$1");

    setHourValue(value);
    if (onChange) {
      onChange({
        ...event,
        target: {
          ...event.target,
          value: value,
        },
      });
    }
  };

  return (
    <input type="text" onChange={handleChange} value={hourValue} {...props} />
  );
};

export { InputHour };
