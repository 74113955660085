import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getBearingById,
} from "../core/_requests";
import { BearingsForm } from "../bearings-form/BearingsForm";

const BearingsEdit = () => {
  const { bearingId } = useParams();
  
  const {
    isLoading,
    data: bearing,
    error,
  } = useQuery(
    `BEARING-${bearingId}`,
    () => {
      if(bearingId) {
        return getBearingById(bearingId!)
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <>
      {
        bearingId
        ?
        !isLoading && !error && bearing && (
          <BearingsForm bearing={bearing} />
        )
        :
          <BearingsForm bearing={{id: undefined}} />
      }
    </>
  );
};

export { BearingsEdit };
