import { FC, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";
import {
  AlarmParameterization,
  AlarmParameterizationInitialValue,
} from "../core/_models";
import {
  createAlarmParameterization,
  updateAlarmParameterization,
} from "../core/_requests";
import { Toast } from "../../../../../theme/widgets/alerts/Toast";
import { InputHour } from "../../../../../theme/helpers/form-helper/InputHour";

type AlarmParameterizationFormProps = {
  parameterization: AlarmParameterization;
};

const AlarmParameterizationsForm: FC<AlarmParameterizationFormProps> = ({
  parameterization,
}) => {
  const { parameterizationId } = useParams();
  const navigate = useNavigate();
  const [minValue, setMinValue] = useState(
    parameterization.alarm_limits_min ?? 0
  );
  const [maxValue, setMaxValue] = useState(
    parameterization.alarm_limits_max ?? 100
  );
  const [isSpectrumCollectionActive, setIsSpectrumCollectionActive] = useState<boolean>(parameterization.spectrum_collection ? true : false);
  const handleInput = (e: ChangeResult) => {
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);
  };

  const [parameterizationEdit] = useState<AlarmParameterization>({
    ...parameterization,
    alarm_limits_min:
      parameterization.alarm_limits_min ||
      AlarmParameterizationInitialValue.alarm_limits_min,
    alarm_limits_max:
      parameterization.alarm_limits_max ||
      AlarmParameterizationInitialValue.alarm_limits_max,
    data_collection_time:
      parameterization.data_collection_time ||
      AlarmParameterizationInitialValue.data_collection_time,
    spectrum_collection:
      parameterization.spectrum_collection ||
      AlarmParameterizationInitialValue.spectrum_collection,
    description:
      parameterization.description ||
      AlarmParameterizationInitialValue.description,
  });

  const update = useMutation(
    (values: AlarmParameterization) => updateAlarmParameterization(values),
    {
      onSuccess: (response) => {
        Toast.fire({
          icon: "success",
          title: response.message,
          customClass: {
            popup: "bg-success",
            title: "text-light",
          },
        });
      },
      onError: (error: any) => {
        console.log(error);
        Toast.fire({
          icon: "error",
          iconHtml: "<span>!</span>",
          title: "Erro ao cadastrar o parametrização'.",
          customClass: {
            popup: "bg-danger",
            title: "text-light",
          },
        });
      },
    }
  );

  const create = useMutation(
    (values: AlarmParameterization) => createAlarmParameterization(values),
    {
      onSuccess: (response) => {
        Toast.fire({
          icon: "success",
          title: response.message,
          customClass: {
            popup: "bg-success",
            title: "text-light",
          },
        });
        navigate(-1);
      },
      onError: (error: any) => {
        console.log(error);
        error.response.data.data.name.map((message: string) => {
          Toast.fire({
            icon: "error",
            iconHtml: "<span>!</span>",
            title: message,
            customClass: {
              popup: "bg-danger",
              title: "text-light",
            },
          });
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: parameterizationEdit,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      values.alarm_limits_max = maxValue;
      values.alarm_limits_min = minValue;
      if(!isSpectrumCollectionActive) {
        values.spectrum_collection = ""
      }

      try {
        if (parameterizationId) {
          return update.mutateAsync(values);
        } else {
          return create.mutateAsync(values);
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <div className="mt-3 row justify-content-between align-items-center border border-color-ccc shadow-sm rounded bg-white col-11 mx-auto py-3 ">
        <div className="row mb-5">
          <div className="col-12">
            <h2>{`${
              parameterization.id ? "Editar" : "Nova"
            } Parametrização de Alarmes`}</h2>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="">
                    <label>
                      Limites de alarme <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex justify-content-between">
                      <div>
                        <small>Min: </small>
                        <small>{minValue}</small>
                      </div>
                      <div>
                        <small>Max: </small>
                        <small>{maxValue}</small>
                      </div>
                    </div>
                    <MultiRangeSlider
                      min={0}
                      max={100}
                      step={5}
                      minValue={minValue}
                      maxValue={maxValue}
                      ruler={false}
                      onInput={(e) => {
                        handleInput(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="form-group">
                    <label htmlFor="data_collection_time">
                      Tempo para coleta de daddos{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <div>
                      <small>{formik.values.data_collection_time}</small>
                      <small>
                        {formik.values.data_collection_time! > 1
                          ? " segundos"
                          : " segundo"}
                      </small>
                    </div>
                    <input
                      {...formik.getFieldProps("data_collection_time")}
                      type="range"
                      name="data_collection_time"
                      id="data_collection_time"
                      className="form-range pt-4"
                      min={1}
                      max={60}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                <div className="form-switch form-switch-md d-flex justify-content-between ps-0">
                  <label className="form-check-label" htmlFor="spectrum_collection_active">Coleta de espectro</label>
                  <input 
                    className="form-check-input" 
                    type="checkbox" 
                    role="switch" 
                    id="spectrum_collection_active" 
                    checked={isSpectrumCollectionActive}
                    onChange={e => {
                      setIsSpectrumCollectionActive(e.target.checked)
                    }}
                  />
                </div>
                  <div className="form-group">
                    {
                      !!isSpectrumCollectionActive && (
                        <InputHour
                          {...formik.getFieldProps("spectrum_collection")}
                          className="form-control mt-3"
                          name="spectrum_collection"
                          placeholder="00:00"
                          disabled={formik.isSubmitting}
                          onChange={(e: any) => {
                            formik.setFieldValue(
                              "spectrum_collection",
                              e.target.value
                            );
                          }}
                        />
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4 mb-3">
                  <div className="form-group">
                    <label htmlFor="description">Descrição</label>
                    <input
                      {...formik.getFieldProps("description")}
                      type="text"
                      name="description"
                      id="description"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12">
                  <Link
                    to={"/alarm-parameterizations"}
                    className="btn btn-danger"
                  >
                    <i className="bi bi-arrow-left"></i> Voltar
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-primary ms-3"
                    disabled={
                      formik.isSubmitting || !formik.isValid || !formik.touched
                    }
                  >
                    <span>Salvar</span>
                    {formik.isSubmitting && (
                      <div
                        className="spinner-border spinner-border-sm ms-2"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export { AlarmParameterizationsForm };
