/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  WithChildren,
  initialQueryResponse,
} from "../../../../../theme/helpers/Model";
import {
  createResponseContext,
  stringifyRequestQuery,
  isNotEmpty,
} from "../../../../../theme/helpers/CrudHelpers";
import { getTemperature } from "./_requests";
import { LinearChartModel } from "./_models";
import { useTemperatureRequest } from "./TemperatureRequestProvider";

const TemperatureResponseContext =
  createResponseContext<LinearChartModel>(initialQueryResponse);
const TemperatureResponseProvider: FC<WithChildren> = ({ children }) => {
  const { equipmentId } = useParams();
  const { state } = useTemperatureRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const enabledQuery: boolean = isNotEmpty(query);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `ACODATA_IOT_LIST_TEMPERATURE-${query}`,
    () => {
      return getTemperature(parseInt(equipmentId!), query);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
    }
  );

  return (
    <TemperatureResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </TemperatureResponseContext.Provider>
  );
};

const useTemperatureResponse = () => useContext(TemperatureResponseContext);

const useTemperatureResponseData = () => {
  const { response } = useTemperatureResponse();

  if (!response) {
    return [];
  }

  return response || [];
};

const useTemperatureResponseLoading = (): boolean => {
  const { isLoading } = useTemperatureResponse();
  return isLoading;
};

export {
  TemperatureResponseProvider,
  useTemperatureResponse,
  useTemperatureResponseData,
  useTemperatureResponseLoading,
};
