import { FC, useState, createContext, useContext } from "react";
import {
  Search,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from "../../../../../theme/helpers/Model";

const VelocityRequestContext =
  createContext<QueryRequestContextProps>(initialQueryRequest);

const VelocityRequestProvider: FC<WithChildren> = ({ children }) => {
  const [state, setState] = useState<Search>(initialQueryRequest.state);

  const updateState = (updates: Partial<Search>) => {
    const updatedState = { ...state, ...updates } as Search;
    setState(updatedState);
  };

  return (
    <VelocityRequestContext.Provider value={{ state, updateState }}>
      {children}
    </VelocityRequestContext.Provider>
  );
};

const useVelocityRequest = () => useContext(VelocityRequestContext);
export { VelocityRequestProvider, useVelocityRequest };
