import { SettingsEdit } from "./components/settings-edit/SettingsEdit";

const SettingsPage = () => {

  return (
    <SettingsEdit />
  );
};

export default SettingsPage;
