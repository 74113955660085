type Area = {
    id: number
    area: string
}

type Cell = {
    id: number
    cell: string
}

export type User = {
    id?: number,
    name?: string,
    email?: string,
    function?: string,
    phone?: string,
    user?: string,
    password?: string,
    password_confirmation?: string,
    profile_id?: number,
    areas_ids?: number[],
    areas?: Area[],
    cells?: Cell[],
    cells_ids?: number[]
}

export type ResponseUser = {
    message: string
}

export const UserInitialValue: User = {
    id: 0,
    name: '',
    email: '',
    function: '',
    phone: '',
    user: '',
    password: '',
    password_confirmation: '',
    profile_id: undefined,
    areas: undefined
}