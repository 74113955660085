export type Setting = {
    smartcelula_client_id?: string
}

export type ResponseSetting = {
    message: string
}

export const SettingInitialValue: Setting = {
    smartcelula_client_id: ''
}