import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getClientById,
} from "../core/_requests";
import { ClientsForm } from "../clients-form/ClientsForm";

const ClientsEdit = () => {
  const { clientId } = useParams();
  
  const {
    isLoading,
    data: client,
    error,
  } = useQuery(
    `CLIENT-${clientId}`,
    () => {
      if(clientId) {
        return getClientById(clientId!)
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <>
      {
        clientId
        ?
        !isLoading && !error && client && (
          <ClientsForm client={client} />
        )
        :
        <ClientsForm client={{id: undefined}} />
      }
    </>
  );
};

export { ClientsEdit };
