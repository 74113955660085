import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Outlet, useLocation } from "react-router-dom";
import { SidebarLogo } from "./components/sidebar/SidebarLogo";
import { SidebarMenuMain } from "./components/sidebar/sidebar-menu/SidebarMenuMain";

type LayoutContextType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isMobile: boolean;
};

const LayoutContext = createContext<LayoutContextType>({
  open: true,
  setOpen: () => {},
  isMobile: false,
});

const MasterLayout = () => {
  const isMobile = window.innerWidth <= 768;
  const [open, setOpen] = useState(!isMobile);
  const location = useLocation();

  return (
    <LayoutContext.Provider value={{ open, setOpen, isMobile }}>
      {isMobile && open && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2,
          }}
        />
      )}

      <div className="d-flex h-100 z-5">
        {isMobile && !open && (
          <button
            className={`position-absolute cursor-pointer border-2 translate-end-0 top-0 z-3 outline-none btn btn-primary m-2 py-1 px-2 shadow`}
            onClick={() => setOpen(!open)}
            style={{
              backgroundColor: "#0d6efd",
            }}
          >
            <i
              className="bi bi-list"
              style={{
                fontSize: "20px",
              }}
            ></i>
          </button>
        )}

        <div
          className={` ${
            open
              ? "w-xl-250px p-4"
              : "w-xl-100px p-0 d-flex flex-column align-items-center icons-size"
          }  transition-all-300 h-100 position-relative py-8 z-3 shadow-sm p-3 mb-5 bg-white  ${
            isMobile ? "position-fixed" : ""
          }`}
          style={
            isMobile
              ? open
                ? { transform: "translateX(0)" }
                : { transform: "translateX(-100%)" }
              : {}
          }
        >
          <div className="mb-5">
            <span
              className={`text-blue font-weight-medium fs-5 ${
                open && "d-none"
              }`}
            >
              <i
                className={`bi bi-list border-dark-purple border-2 rounded-circle translate-end-0 top-0 start-100 sidebar-icons`}
                onClick={() => setOpen(!open)}
              ></i>
            </span>

            <div
              className={`d-flex align-items-center justify-content-between gap-5 ${
                !open && "d-none"
              }`}
            >
              <SidebarLogo />

              <i
                className={`sidebar-icons ${
                  isMobile ? "bi bi-x-lg" : "bi bi-list-nested "
                }`}
                onClick={() => setOpen(!open)}
              ></i>
            </div>
          </div>

          <SidebarMenuMain />
        </div>

        <div className="app-main flex-column flex-grow-1 w-100 w-lg-75 ">
          <div className="app-main d-flex flex-column py-5 px-2">
            <Outlet />
          </div>
        </div>
      </div>
    </LayoutContext.Provider>
  );
};

const useLayoutContext = () => useContext(LayoutContext);

export { MasterLayout, useLayoutContext };
