import { FC, useState, createContext, useContext } from "react";
import {
  Search,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from "../../../../../theme/helpers/Model";

const AccelerationRequestContext =
  createContext<QueryRequestContextProps>(initialQueryRequest);

const AccelerationRequestProvider: FC<WithChildren> = ({ children }) => {
  const [state, setState] = useState<Search>(initialQueryRequest.state);

  const updateState = (updates: Partial<Search>) => {
    const updatedState = { ...state, ...updates } as Search;
    setState(updatedState);
  };

  return (
    <AccelerationRequestContext.Provider value={{ state, updateState }}>
      {children}
    </AccelerationRequestContext.Provider>
  );
};

const useAccelerationRequest = () => useContext(AccelerationRequestContext);
export { AccelerationRequestProvider, useAccelerationRequest };
