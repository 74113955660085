import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getCellById,
} from "../core/_requests";
import { CellsForm } from "../cells-form/CellsForm";

const CellsEdit = () => {
  const { cellId } = useParams();
  
  const {
    isLoading,
    data: cell,
    error,
  } = useQuery(
    `CELL-${cellId}`,
    () => {
      if(cellId) {
        return getCellById(cellId!)
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <>
      {
        cellId
        ?
        !isLoading && !error && cell && (
          <CellsForm cell={cell} />
        )
        :
        <CellsForm cell={{id: undefined}} />
      }
    </>
  );
};

export { CellsEdit };
