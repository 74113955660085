/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  WithChildren,
  initialQueryResponse,
} from "../../../../../theme/helpers/Model";
import {
  createResponseContext,
  stringifyRequestQuery,
  isNotEmpty,
} from "../../../../../theme/helpers/CrudHelpers";
import { getCaptures } from "./_requests";
import { LinearChartModel } from "./_models";
import { useVelocityRequest } from "./VelocityRequestProvider";

const VelocityResponseContext =
  createResponseContext<LinearChartModel>(initialQueryResponse);
const VelocityResponseProvider: FC<WithChildren> = ({ children }) => {
  const { equipmentId } = useParams();
  const { state, updateState } = useVelocityRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const enabledQuery: boolean = isNotEmpty(query);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  // useEffect(() => {
  //   updateState({ filter: { data_type: "rms_velocity", date: "20" } });
  // }, []);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `ACODATA_IOT_LIST_VELOCITY-${query}`,
    () => {
      return getCaptures(parseInt(equipmentId!), query);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
    }
  );

  return (
    <VelocityResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </VelocityResponseContext.Provider>
  );
};

const useVelocityResponse = () => useContext(VelocityResponseContext);

const useVelocityResponseData = () => {
  const { response } = useVelocityResponse();

  if (!response) {
    return [];
  }

  return response || [];
};

const useVelocityResponseLoading = (): boolean => {
  const { isLoading } = useVelocityResponse();
  return isLoading;
};

export {
  VelocityResponseProvider,
  useVelocityResponse,
  useVelocityResponseData,
  useVelocityResponseLoading,
};
