import { FC, useEffect, useState, ChangeEvent } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation, useQuery, useQueries } from "react-query";
import { Equipment, EquipmentInitialValue } from "../core/_models";
import { createEquipment, updateEquipment } from "../core/_requests";
import { Toast } from "../../../../../theme/widgets/alerts/Toast";
import { getAreas } from "../../../areas/components/core/_requests";
import { getCells } from "../../../cells/components/core/_requests";
import { Tooltip } from "../../../../components/Tooltip";
import { Cell } from "../../../cells/components/core/_models";
import { getBearings } from "../../../bearings/components/core/_requests";

type EquipmentFormProps = {
  equipment: Equipment;
};

const EquipmentForm: FC<EquipmentFormProps> = ({ equipment }) => {
  const { equipmentId } = useParams();
  const navigate = useNavigate();
  const [cells, setCells] = useState<Array<Cell>>();
  const [bpfi, setBpfi] = useState<Array<string>>([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [bpfo, setBpfo] = useState<Array<string>>([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [bsf, setBsf] = useState<Array<string>>([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [fft, setFft] = useState<Array<string>>([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const bearings = ["R1", "R2", "R3", "R4", "R5", "R6", "R7", "R8"];

  const { data: areas } = useQuery(
    `AREAS_LIST`,
    () => {
      return getAreas();
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const bearingsList = useQueries(
    bearings.map((bearing) => {
      return {
        queryKey: ["r1", bearing],
        queryFn: () => getBearings("type=" + bearing),
      };
    })
  );

  const [equipmentEdit] = useState<Equipment>({
    ...equipment,
    description: equipment.description || EquipmentInitialValue.description,
    tag: equipment.tag || EquipmentInitialValue.tag,
    area_id: equipment.area_id || EquipmentInitialValue.area_id,
    cell_id: equipment.cell_id || EquipmentInitialValue.cell_id,
    subset: equipment.subset || EquipmentInitialValue.subset,
    data_collection: equipment.data_collection || EquipmentInitialValue.data_collection,
  });

  const loadCells = useMutation(
    (areaId: number) => getCells(`area=${areaId.toString()}`),
    {
      onSuccess(data) {
        setCells(data);
      },
    }
  );

  const onSelectBearing = (
    event: ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const nextBpfi = bpfi.map((b, i) => {
      if (i === index) {
        return (b =
          event.target[event.target.selectedIndex].getAttribute("data-bpfi")!);
        // return b = bearingsList[index].data?.filter(bearing => bearing.id === parseInt(event.target.value).bp)
      } else {
        return b;
      }
    });
    const nextBpfo = bpfo.map((b, i) => {
      if (i === index) {
        return (b =
          event.target[event.target.selectedIndex].getAttribute("data-bpfo")!);
      } else {
        return b;
      }
    });
    const nextBsf = bsf.map((b, i) => {
      if (i === index) {
        return (b =
          event.target[event.target.selectedIndex].getAttribute("data-bsf")!);
      } else {
        return b;
      }
    });
    const nextFft = fft.map((b, i) => {
      if (i === index) {
        return (b =
          event.target[event.target.selectedIndex].getAttribute("data-fft")!);
      } else {
        return b;
      }
    });

    setBpfi(nextBpfi);
    setBpfo(nextBpfo);
    setBsf(nextBsf);
    setFft(nextFft);
  };

  useEffect(() => {
    if (equipmentEdit.area_id) {
      loadCells.mutateAsync(equipmentEdit.area_id);
    }
  }, [equipmentEdit.area_id]);

  useEffect(() => {
    var r1 = bearingsList[0].data?.find(
      (bearing) => bearing.id === equipment.bearing_r1_id
    );
    var r2 = bearingsList[1].data?.find(
      (bearing) => bearing.id === equipment.bearing_r2_id
    );
    var r3 = bearingsList[2].data?.find(
      (bearing) => bearing.id === equipment.bearing_r3_id
    );
    var r4 = bearingsList[3].data?.find(
      (bearing) => bearing.id === equipment.bearing_r4_id
    );
    var r5 = bearingsList[4].data?.find(
      (bearing) => bearing.id === equipment.bearing_r5_id
    );
    var r6 = bearingsList[5].data?.find(
      (bearing) => bearing.id === equipment.bearing_r6_id
    );
    var r7 = bearingsList[6].data?.find(
      (bearing) => bearing.id === equipment.bearing_r7_id
    );
    var r8 = bearingsList[7].data?.find(
      (bearing) => bearing.id === equipment.bearing_r8_id
    );

    var nextBpfi = bpfi.map((b, i) => {
      if (i === 0) {
        return (b = r1?.bpfi!);
      } else if (i === 1) {
        return (b = r2?.bpfi!);
      } else if (i === 2) {
        return (b = r3?.bpfi!);
      } else if (i === 3) {
        return (b = r4?.bpfi!);
      } else if (i === 4) {
        return (b = r5?.bpfi!);
      } else if (i === 5) {
        return (b = r6?.bpfi!);
      } else if (i === 6) {
        return (b = r7?.bpfi!);
      } else if (i === 7) {
        return (b = r8?.bpfi!);
      } else {
        return b;
      }
    });
    var nextBpfo = bpfo.map((b, i) => {
      if (i === 0) {
        return (b = r1?.bpfo!);
      } else if (i === 1) {
        return (b = r2?.bpfo!);
      } else if (i === 2) {
        return (b = r3?.bpfo!);
      } else if (i === 3) {
        return (b = r4?.bpfo!);
      } else if (i === 4) {
        return (b = r5?.bpfo!);
      } else if (i === 5) {
        return (b = r6?.bpfo!);
      } else if (i === 6) {
        return (b = r7?.bpfo!);
      } else if (i === 7) {
        return (b = r8?.bpfo!);
      } else {
        return b;
      }
    });
    var nextBsf = bsf.map((b, i) => {
      if (i === 0) {
        return (b = r1?.bsf!);
      } else if (i === 1) {
        return (b = r2?.bsf!);
      } else if (i === 2) {
        return (b = r3?.bsf!);
      } else if (i === 3) {
        return (b = r4?.bsf!);
      } else if (i === 4) {
        return (b = r5?.bsf!);
      } else if (i === 5) {
        return (b = r6?.bsf!);
      } else if (i === 6) {
        return (b = r7?.bsf!);
      } else if (i === 7) {
        return (b = r8?.bsf!);
      } else {
        return b;
      }
    });
    var nextFft = fft.map((b, i) => {
      if (i === 0) {
        return (b = r1?.fft!);
      } else if (i === 1) {
        return (b = r2?.fft!);
      } else if (i === 2) {
        return (b = r3?.fft!);
      } else if (i === 3) {
        return (b = r4?.fft!);
      } else if (i === 4) {
        return (b = r5?.fft!);
      } else if (i === 5) {
        return (b = r6?.fft!);
      } else if (i === 6) {
        return (b = r7?.fft!);
      } else if (i === 7) {
        return (b = r8?.fft!);
      } else {
        return b;
      }
    });

    setBpfi(nextBpfi);
    setBpfo(nextBpfo);
    setBsf(nextBsf);
    setFft(nextFft);
  }, []);

  const update = useMutation((values: Equipment) => updateEquipment(values), {
    onSuccess: (response) => {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          popup: "bg-success",
          title: "text-light",
        },
      });
    },
    onError: (error: any) => {
      console.log(error);
      Toast.fire({
        icon: "error",
        iconHtml: "<span>!</span>",
        title: "Erro ao cadastrar o equipamento'.",
        customClass: {
          popup: "bg-danger",
          title: "text-light",
        },
      });
    },
  });

  const create = useMutation((values: Equipment) => createEquipment(values), {
    onSuccess: (response) => {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          popup: "bg-success",
          title: "text-light",
        },
      });
      navigate(-1);
    },
    onError: (error: any) => {
      console.log(error);
      error.response.data.data.name.map((message: string) => {
        Toast.fire({
          icon: "error",
          iconHtml: "<span>!</span>",
          title: message,
          customClass: {
            popup: "bg-danger",
            title: "text-light",
          },
        });
      });
    },
  });

  const formik = useFormik({
    initialValues: equipmentEdit,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        if (equipmentId) {
          return update.mutateAsync(values);
        } else {
          return create.mutateAsync(values);
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <div className="row justify-content-between align-items-center border border-color-ccc shadow-sm rounded bg-white col-11 mx-auto py-3">
        <div className="row mb-5">
          <div className="col-12">
            <h2>{`${equipment.id ? "Editar" : "Novo"} Equipamento`}</h2>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-3 mb-3">
                  <div className="form-group">
                    <label htmlFor="description">
                      Descrição <span className="text-danger">*</span>
                    </label>
                    <input
                      {...formik.getFieldProps("description")}
                      type="string"
                      name="description"
                      id="description"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="form-group">
                    <label htmlFor="tag">
                      Tag <span className="text-danger">*</span>
                    </label>
                    <input
                      {...formik.getFieldProps("tag")}
                      type="string"
                      name="tag"
                      id="tag"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-2 mb-3">
                  <div className="form-group">
                    <label htmlFor="area_id">
                      Área <span className="text-danger">*</span>
                    </label>
                    <select
                      {...formik.getFieldProps("area_id")}
                      required
                      name="area_id"
                      id="area_id"
                      className="form-select"
                      onChange={(event) => {
                        loadCells.mutateAsync(parseInt(event.target.value));
                        formik.setFieldValue("area_id", event.target.value);
                      }}
                    >
                      <option value="">Selecione uma área</option>
                      {areas &&
                        areas.map((area, index) => (
                          <option key={index} value={area.id}>
                            {area.area}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-2 mb-3">
                  <div className="form-group">
                    <label htmlFor="cell_id">
                      Célula <span className="text-danger">*</span>
                    </label>
                    <select
                      {...formik.getFieldProps("cell_id")}
                      required
                      name="cell_id"
                      id="cell_id"
                      className="form-select"
                    >
                      <option value="">Selecione uma célula</option>
                      {cells &&
                        cells.map((cell, index) => (
                          <option key={index} value={cell.id}>
                            {cell.cell}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-2 mb-3">
                  <div className="form-group">
                    <label htmlFor="subset">
                      Subconjunto <span className="text-danger">*</span>
                    </label>
                    <input
                      {...formik.getFieldProps("subset")}
                      type="string"
                      name="subset"
                      id="subset"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 mb-3">
                  <div className="form-group">
                    <label htmlFor="mac">MAC</label>
                    <input
                      {...formik.getFieldProps("mac")}
                      type="text"
                      name="mac"
                      id="mac"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="form-group">
                    <label htmlFor="esn">ESN</label>
                    <input
                      {...formik.getFieldProps("esn")}
                      type="text"
                      name="esn"
                      id="esn"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-2 mb-3">
                  <div className="form-group">
                    <label htmlFor="data_collection">
                      Período da coleta de dados
                    </label>
                    <select
                      {...formik.getFieldProps("data_collection")}
                      required
                      name="data_collection"
                      id="data_collection"
                      className="form-select"
                    >
                      <option value="5">5 minutos</option>
                      <option value="10">10 minutos</option>
                      <option value="15">15 minutos</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr className="my-4"></hr>
              <div className="col-12">
                <h4 className="fw-bold mb-4">
                  <i className="bi bi-gear"></i> Detalhes Técnicos
                </h4>
              </div>
              <div className="border rounded px-3">
                <div className="row py-3">
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="rotation_speed">
                        Velocidade de funcionamento
                      </label>
                      <input
                        {...formik.getFieldProps("rotation_speed")}
                        type="number"
                        name="rotation_speed"
                        id="rotation_speed"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="power">Potência</label>
                      <input
                        {...formik.getFieldProps("power")}
                        type="number"
                        name="power"
                        id="power"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="blades">Número de pás</label>
                      <input
                        {...formik.getFieldProps("blades")}
                        type="number"
                        name="blades"
                        id="blades"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-2 mb-3">
                    <label
                      className="form-check-label"
                      htmlFor="frequency_inverter"
                    >
                      Inversor de frequência
                    </label>
                    <div className="form-check form-switch form-switch-md">
                      <input
                        {...formik.getFieldProps("frequency_inverter")}
                        className="form-check-input"
                        type="checkbox"
                        name="frequency_inverter"
                        id="frequency_inverter"
                        checked={formik.values.frequency_inverter!}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "frequency_inverter",
                            e.target.checked
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                {/* <hr/> */}
                <div className="row pt-3">
                  <div className="col-12">
                    <h5 className="fw-bold mb-4">
                      <i className="bi bi-gear"></i> Engrenamentos
                    </h5>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="gear_z1">Engrenamento Z1</label>
                      <input
                        {...formik.getFieldProps("gear_z1")}
                        type="text"
                        name="gear_z1"
                        id="gear_z1"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="gear_z2">Engrenamento Z2</label>
                      <input
                        {...formik.getFieldProps("gear_z2")}
                        type="text"
                        name="gear_z2"
                        id="gear_z2"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="gear_z3">Engrenamento Z3</label>
                      <input
                        {...formik.getFieldProps("gear_z3")}
                        type="text"
                        name="gear_z3"
                        id="gear_z3"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="gear_z4">Engrenamento Z4</label>
                      <input
                        {...formik.getFieldProps("gear_z4")}
                        type="text"
                        name="gear_z4"
                        id="gear_z4"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="gear_z5">Engrenamento Z5</label>
                      <input
                        {...formik.getFieldProps("gear_z5")}
                        type="text"
                        name="gear_z5"
                        id="gear_z5"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="gear_z6">Engrenamento Z6</label>
                      <input
                        {...formik.getFieldProps("gear_z6")}
                        type="text"
                        name="gear_z6"
                        id="gear_z6"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="gear_z7">Engrenamento Z7</label>
                      <input
                        {...formik.getFieldProps("gear_z7")}
                        type="text"
                        name="gear_z7"
                        id="gear_z7"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="gear_z8">Engrenamento Z8</label>
                      <input
                        {...formik.getFieldProps("gear_z8")}
                        type="text"
                        name="gear_z8"
                        id="gear_z8"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-12">
                    <h5 className="fw-bold mb-4">
                      <i className="bi bi-gear"></i> Rolamentos
                    </h5>
                  </div>
                  <div className="col-12 mb-2">
                    <h6 className="fw-bold">
                      <u>Rolamento R1</u>
                    </h6>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r1_id">R1</label>
                          <select
                            {...formik.getFieldProps("bearing_r1_id")}
                            name="bearing_r1_id"
                            id="bearing_r1_id"
                            className="form-select"
                            onChange={(event) => {
                              onSelectBearing(event, 0);
                              formik.setFieldValue(
                                "bearing_r1_id",
                                event.target.value
                              );
                            }}
                          >
                            <option value="">
                              Selecione um rolamento do tipo R1
                            </option>
                            {bearingsList &&
                              bearingsList[0].data?.map((bearing, index) => (
                                <option
                                  key={index}
                                  value={bearing.id}
                                  data-bpfi={bearing.bpfi}
                                  data-bpfo={bearing.bpfo}
                                  data-bsf={bearing.bsf}
                                  data-fft={bearing.fft}
                                >
                                  {bearing.model} - {bearing.manufacturer}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r1_f1">BPFI</label>{" "}
                          <Tooltip text="BPFI – Ponto do anel interno.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfi[0]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r1_f2">BPFO</label>{" "}
                          <Tooltip text="BPFO – Ponto do anel externo.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfo[0]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r1_f3">BSF</label>{" "}
                          <Tooltip text="BSF – Elemento rolante.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bsf[0]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r1_f4">FFT</label>{" "}
                          <Tooltip text="FFT – Conjunto de elementos rolantes e gaiola.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{fft[0]}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <h6 className="fw-bold">
                      <u>Rolamento R2</u>
                    </h6>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r2">R2</label>
                          <select
                            {...formik.getFieldProps("bearing_r2_id")}
                            name="bearing_r2_id"
                            id="bearing_r2_id"
                            className="form-select"
                            onChange={(event) => {
                              onSelectBearing(event, 1);
                              formik.setFieldValue(
                                "bearing_r2_id",
                                event.target.value
                              );
                            }}
                          >
                            <option value="">
                              Selecione um rolamento do tipo R2
                            </option>
                            {bearingsList &&
                              bearingsList[1].data?.map((bearing, index) => (
                                <option
                                  key={index}
                                  value={bearing.id}
                                  data-bpfi={bearing.bpfi}
                                  data-bpfo={bearing.bpfo}
                                  data-bsf={bearing.bsf}
                                  data-fft={bearing.fft}
                                >
                                  {bearing.model} - {bearing.manufacturer}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r2_f1">BPFI</label>{" "}
                          <Tooltip text="BPFI – Ponto do anel interno.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfi[1]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r2_f2">BPFO</label>{" "}
                          <Tooltip text="BPFO – Ponto do anel externo.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfo[1]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r2_f3">BSF</label>{" "}
                          <Tooltip text="BSF – Elemento rolante.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bsf[1]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r2_f4">FFT</label>{" "}
                          <Tooltip text="FFT – Conjunto de elementos rolantes e gaiola.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{fft[1]}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <h6 className="fw-bold">
                      <u>Rolamento R3</u>
                    </h6>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r3">R3</label>
                          <select
                            {...formik.getFieldProps("bearing_r3_id")}
                            name="bearing_r3_id"
                            id="bearing_r3_id"
                            className="form-select"
                            onChange={(event) => {
                              onSelectBearing(event, 2);
                              formik.setFieldValue(
                                "bearing_r3_id",
                                event.target.value
                              );
                            }}
                          >
                            <option value="">
                              Selecione um rolamento do tipo R3
                            </option>
                            {bearingsList &&
                              bearingsList[2].data?.map((bearing, index) => (
                                <option
                                  key={index}
                                  value={bearing.id}
                                  data-bpfi={bearing.bpfi}
                                  data-bpfo={bearing.bpfo}
                                  data-bsf={bearing.bsf}
                                  data-fft={bearing.fft}
                                >
                                  {bearing.model} - {bearing.manufacturer}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r3_f1">BPFI</label>{" "}
                          <Tooltip text="BPFI – Ponto do anel interno.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfi[2]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r3_f2">BPFO</label>{" "}
                          <Tooltip text="BPFO – Ponto do anel externo.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfo[2]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r3_f3">BSF</label>{" "}
                          <Tooltip text="BSF – Elemento rolante.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bsf[2]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r3_f4">FFT</label>{" "}
                          <Tooltip text="FFT – Conjunto de elementos rolantes e gaiola.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{fft[2]}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <h6 className="fw-bold">
                      <u>Rolamento R4</u>
                    </h6>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r4">R4</label>
                          <select
                            {...formik.getFieldProps("bearing_r4_id")}
                            name="bearing_r4_id"
                            id="bearing_r4_id"
                            className="form-select"
                            onChange={(event) => {
                              onSelectBearing(event, 3);
                              formik.setFieldValue(
                                "bearing_r4_id",
                                event.target.value
                              );
                            }}
                          >
                            <option value="">
                              Selecione um rolamento do tipo R4
                            </option>
                            {bearingsList &&
                              bearingsList[3].data?.map((bearing, index) => (
                                <option
                                  key={index}
                                  value={bearing.id}
                                  data-bpfi={bearing.bpfi}
                                  data-bpfo={bearing.bpfo}
                                  data-bsf={bearing.bsf}
                                  data-fft={bearing.fft}
                                >
                                  {bearing.model} - {bearing.manufacturer}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r4_f1">BPFI</label>{" "}
                          <Tooltip text="BPFI – Ponto do anel interno.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfi[3]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r4_f2">BPFO</label>{" "}
                          <Tooltip text="BPFO – Ponto do anel externo.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfo[3]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r4_f3">BSF</label>{" "}
                          <Tooltip text="BSF – Elemento rolante.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bsf[3]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r4_f4">FFT</label>{" "}
                          <Tooltip text="FFT – Conjunto de elementos rolantes e gaiola.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{fft[3]}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <h6 className="fw-bold">
                      <u>Rolamento R5</u>
                    </h6>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r5">R5</label>
                          <select
                            {...formik.getFieldProps("bearing_r5_id")}
                            name="bearing_r5_id"
                            id="bearing_r5_id"
                            className="form-select"
                            onChange={(event) => {
                              onSelectBearing(event, 4);
                              formik.setFieldValue(
                                "bearing_r5_id",
                                event.target.value
                              );
                            }}
                          >
                            <option value="">
                              Selecione um rolamento do tipo R5
                            </option>
                            {bearingsList &&
                              bearingsList[4].data?.map((bearing, index) => (
                                <option
                                  key={index}
                                  value={bearing.id}
                                  data-bpfi={bearing.bpfi}
                                  data-bpfo={bearing.bpfo}
                                  data-bsf={bearing.bsf}
                                  data-fft={bearing.fft}
                                >
                                  {bearing.model} - {bearing.manufacturer}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r5_f1">BPFI</label>{" "}
                          <Tooltip text="BPFI – Ponto do anel interno.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfi[4]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r5_f2">BPFO</label>{" "}
                          <Tooltip text="BPFO – Ponto do anel externo.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfo[4]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r5_f3">BSF</label>{" "}
                          <Tooltip text="BSF – Elemento rolante.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bsf[4]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r5_f4">FFT</label>{" "}
                          <Tooltip text="FFT – Conjunto de elementos rolantes e gaiola.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{fft[4]}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <h6 className="fw-bold">
                      <u>Rolamento R6</u>
                    </h6>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r6">R6</label>
                          <select
                            {...formik.getFieldProps("bearing_r6_id")}
                            name="bearing_r6_id"
                            id="bearing_r6_id"
                            className="form-select"
                            onChange={(event) => {
                              onSelectBearing(event, 5);
                              formik.setFieldValue(
                                "bearing_r6_id",
                                event.target.value
                              );
                            }}
                          >
                            <option value="">
                              Selecione um rolamento do tipo R6
                            </option>
                            {bearingsList &&
                              bearingsList[5].data?.map((bearing, index) => (
                                <option
                                  key={index}
                                  value={bearing.id}
                                  data-bpfi={bearing.bpfi}
                                  data-bpfo={bearing.bpfo}
                                  data-bsf={bearing.bsf}
                                  data-fft={bearing.fft}
                                >
                                  {bearing.model} - {bearing.manufacturer}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r6_f1">BPFI</label>{" "}
                          <Tooltip text="BPFI – Ponto do anel interno.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfi[5]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r6_f2">BPFO</label>{" "}
                          <Tooltip text="BPFO – Ponto do anel externo.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfo[5]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r6_f3">BSF</label>{" "}
                          <Tooltip text="BSF – Elemento rolante.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bsf[5]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r6_f4">FFT</label>{" "}
                          <Tooltip text="FFT – Conjunto de elementos rolantes e gaiola.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{fft[5]}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <h6 className="fw-bold">
                      <u>Rolamento R7</u>
                    </h6>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r7">R7</label>
                          <select
                            {...formik.getFieldProps("bearing_r7_id")}
                            name="bearing_r7_id"
                            id="bearing_r7_id"
                            className="form-select"
                            onChange={(event) => {
                              onSelectBearing(event, 6);
                              formik.setFieldValue(
                                "bearing_r7_id",
                                event.target.value
                              );
                            }}
                          >
                            <option value="">
                              Selecione um rolamento do tipo R7
                            </option>
                            {bearingsList &&
                              bearingsList[6].data?.map((bearing, index) => (
                                <option
                                  key={index}
                                  value={bearing.id}
                                  data-bpfi={bearing.bpfi}
                                  data-bpfo={bearing.bpfo}
                                  data-bsf={bearing.bsf}
                                  data-fft={bearing.fft}
                                >
                                  {bearing.model} - {bearing.manufacturer}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r7_f1">BPFI</label>{" "}
                          <Tooltip text="BPFI – Ponto do anel interno.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfi[6]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r7_f2">BPFO</label>{" "}
                          <Tooltip text="BPFO – Ponto do anel externo.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfo[6]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r7_f3">BSF</label>{" "}
                          <Tooltip text="BSF – Elemento rolante.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bsf[6]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r7_f4">FFT</label>{" "}
                          <Tooltip text="FFT – Conjunto de elementos rolantes e gaiola.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{fft[6]}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <h6 className="fw-bold">
                      <u>Rolamento R8</u>
                    </h6>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r8">R8</label>
                          <select
                            {...formik.getFieldProps("bearing_r8_id")}
                            name="bearing_r8_id"
                            id="bearing_r8_id"
                            className="form-select"
                            onChange={(event) => {
                              onSelectBearing(event, 7);
                              formik.setFieldValue(
                                "bearing_r8_id",
                                event.target.value
                              );
                            }}
                          >
                            <option value="">
                              Selecione um rolamento do tipo R8
                            </option>
                            {bearingsList &&
                              bearingsList[7].data?.map((bearing, index) => (
                                <option
                                  key={index}
                                  value={bearing.id}
                                  data-bpfi={bearing.bpfi}
                                  data-bpfo={bearing.bpfo}
                                  data-bsf={bearing.bsf}
                                  data-fft={bearing.fft}
                                >
                                  {bearing.model} - {bearing.manufacturer}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r8_f1">BPFI</label>{" "}
                          <Tooltip text="BPFI – Ponto do anel interno.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfi[7]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r8_f2">BPFO</label>{" "}
                          <Tooltip text="BPFO – Ponto do anel externo.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bpfo[7]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r8_f3">BSF</label>{" "}
                          <Tooltip text="BSF – Elemento rolante.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{bsf[7]}</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <div className="form-group">
                          <label htmlFor="bearing_r8_f4">FFT</label>{" "}
                          <Tooltip text="FFT – Conjunto de elementos rolantes e gaiola.">
                            <i className="bi bi-info-circle-fill text-muted fs-6"></i>
                          </Tooltip>
                          <p>{fft[7]}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  mt-5">
                <div className="col-12">
                  <Link to={"/equipment"} className="btn btn-danger">
                    <i className="bi bi-arrow-left"></i> Voltar
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-primary ms-3"
                    disabled={
                      formik.isSubmitting || !formik.isValid || !formik.touched
                    }
                  >
                    <span>Salvar</span>
                    {!!formik.isSubmitting && (
                      <div
                        className="spinner-border spinner-border-sm ms-2"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export { EquipmentForm };
