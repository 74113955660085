export type Cell = {
    id?: number,
    cell?: string,
    area_id?: number,
    area?: {
        area: string
    }
}

export type ResponseCell = {
    message: string
}

export const CellInitialValue: Cell = {
    id: 0,
    cell: '',
    area_id: undefined
}