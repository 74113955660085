import { FC, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { mask } from "remask";
import Select, {ActionMeta, MultiValue} from 'react-select';
import { User, UserInitialValue } from "../core/_models";
import { createUser, updateUser } from "../core/_requests";
import { AlertBoostrapButtons } from "../../../../../theme/widgets/alerts/AlertBoostrapButtons";
import { Toast } from "../../../../../theme/widgets/alerts/Toast";
import { getProfiles } from "../../../profiles/components/core/_requests";
import { getAreas } from "../../../areas/components/core/_requests";
import { getCells } from "../../../cells/components/core/_requests";
import { Cell } from "../../../cells/components/core/_models";

type ProfilesFormProps = {
  user: User;
};

type SelectType = {
  value: string
  label: string
}

const UsersForm: FC<ProfilesFormProps> = ({ user }) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [selectAreas, setSelectAreas] = useState<Array<SelectType>>([]);
  const [selectCells, setSelectCells] = useState<Array<SelectType>>([]);
  const [initialSelectAreas, setInitialSelectAreas] = useState<Array<SelectType>>([]);
  const [initialSelectCells, setInitialSelectCells] = useState<Array<SelectType>>([]);
  var loadedCells: SelectType[] = [];

  useEffect(() => {
    var tempArea: SelectType[] = []
    var tempAreasIds: number[] = []
    user.areas?.map(area => {
      tempAreasIds.push(area.id);
      tempArea.push({value: area.id!.toString(), label: area.area!})
    })
    
    var tempCell: SelectType[] = []
    var tempCellsIds: number[] = []
    user.cells?.map(cell => {
      tempCellsIds.push(cell.id);
      tempCell.push({value: cell.id!.toString(), label: cell.cell!})
    })

    setInitialSelectAreas(tempArea)
    setInitialSelectCells(tempCell)
    formik.setFieldValue('areas_ids', tempAreasIds)
    formik.setFieldValue('cells_ids', tempCellsIds)

    if (user.areas) {
      user.areas?.map(area => {
        loadCells.mutateAsync(area.id);
      })
    }
  }, [])

  const { data: profiles } = useQuery(
    `PROFILES_LIST`,
    () => {
      return getProfiles();
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: areas } = useQuery(
    `AREAS_LIST`,
    () => {
      return getAreas();
    },
    {
      onSuccess(data) {
        var tempArea: SelectType[] = []
        data.map(area => {
          tempArea.push({value: area.id!.toString(), label: area.area!})
        })

        setSelectAreas(tempArea)
      },
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const [userEdit] = useState<User>({
    ...user,
    name: user.name || UserInitialValue.name,
    email: user.email || UserInitialValue.email,
    function: user.function || UserInitialValue.function,
    phone: user.phone || UserInitialValue.phone,
    user: user.user || UserInitialValue.user,
    password: user.password || UserInitialValue.password,
    password_confirmation:
      user.password_confirmation || UserInitialValue.password_confirmation,
    profile_id: user.profile_id || UserInitialValue.profile_id,
    areas_ids: user.areas_ids || UserInitialValue.areas_ids,
    cells_ids: user.cells_ids || UserInitialValue.cells_ids,
  });

  const updateAreasField = (values: MultiValue<SelectType>, event: ActionMeta<SelectType>) => {
    if (event.action === "select-option" && event.option?.value === "*") { 
      values = selectAreas
    }
    if(event.action === "clear") {
      setSelectCells([])
      setInitialSelectCells([])
    }

    var tempAreas: SelectType[] = []
    var tempAreasIds: number[] = []
    loadedCells = []
    values.map((value: SelectType) => {
      tempAreasIds.push(parseInt(value.value));
      tempAreas.push({value: value.value, label: value.label!})
      loadCells.mutateAsync(parseInt(value.value));
    });
    setInitialSelectAreas(tempAreas)
    formik.setFieldValue('areas_ids', tempAreasIds)
  }
  
  const updateCellsField = (values: MultiValue<SelectType>, event: ActionMeta<SelectType>) => {
    if (event.action === "select-option" && event.option?.value === "*") { 
      values = selectCells
    }
    var tempCells: SelectType[] = []
    var tempCellsIds: number[] = []
    values.map((value: SelectType) => {
      tempCellsIds.push(parseInt(value.value));
      tempCells.push({value: value.value, label: value.label!})
    });

    setInitialSelectCells(tempCells)
    formik.setFieldValue('cells_ids', tempCellsIds)
  }

  const loadCells = useMutation(
    (areaId: number) => getCells(`area=${areaId.toString()}`),
    {
      onSuccess(data) {
        var tempCell: SelectType[] = loadedCells
        data.map(cell => {
          tempCell.push({value: cell.id!.toString(), label: cell.cell!})
        })

        setSelectCells(tempCell)
      },
    }
  );

  const update = useMutation((values: User) => updateUser(values), {
    onSuccess: (response) => {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          popup: "bg-success",
          title: "text-light",
        },
      });
    },
    onError: (error: any) => {
      console.log(error);
      Toast.fire({
        icon: "error",
        iconHtml: "<span>!</span>",
        title: "Erro ao cadastrar perfil.",
        customClass: {
          popup: "bg-danger",
          title: "text-light",
        },
      });
    },
  });

  const create = useMutation((values: User) => createUser(values), {
    onSuccess: (response) => {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          popup: "bg-success",
          title: "text-light",
        },
      });
      navigate(-1);
    },
    onError: (error: any) => {
      console.log(error);
      error.response.data.data.name.map((message: string) => {
        Toast.fire({
          icon: "error",
          iconHtml: "<span>!</span>",
          title: message,
          customClass: {
            popup: "bg-danger",
            title: "text-light",
          },
        });
      });
    },
  });

  const formik = useFormik({
    initialValues: userEdit,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.password !== values.password_confirmation) {
        AlertBoostrapButtons.fire({
          title: "As senhas devem ser iguais",
          icon: "warning",
          timer: 2000,
          showConfirmButton: false,
        });
        return;
      }

      try {
        if (userId) {
          return update.mutateAsync(values);
        } else {
          return create.mutateAsync(values);
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>

      <div className="row justify-content-between align-items-center border border-color-ccc shadow-sm rounded bg-white col-11 mx-auto py-3 mt-4">
        <div className="col-12 mb-lg-5 mb-2">
          <h2>{`${user.id ? "Editar" : "Novo"} Usuário`}</h2>
        </div>
        <div className="col-12">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="row gap-3 gap-lg-0">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="name">
                    Nome <span className="text-danger">*</span>
                  </label>
                  <input
                    {...formik.getFieldProps("name")}
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    required
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="function">
                    Função <span className="text-danger">*</span>
                  </label>
                  <input
                    {...formik.getFieldProps("function")}
                    type="text"
                    name="function"
                    id="function"
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="email">
                    E-mail <span className="text-danger">*</span>
                  </label>
                  <input
                    {...formik.getFieldProps("email")}
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="phone">
                    Telefone <span className="text-danger">*</span>
                  </label>
                  <input
                    {...formik.getFieldProps("phone")}
                    value={mask(formik.values.phone!, "(99) 99999-9999")}
                    type="text"
                    name="phone"
                    id="phone"
                    className="form-control"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="user">
                    Usuário <span className="text-danger">*</span>
                  </label>
                  <input
                    {...formik.getFieldProps("user")}
                    type="text"
                    name="user"
                    id="user"
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="password">
                    Senha {!userId && <span className="text-danger">*</span>}
                  </label>
                  <input
                    {...formik.getFieldProps("password")}
                    autoComplete="new-password"
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    required={userId ? false : true}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="confirm-password">
                    Repita a senha{" "}
                    {!userId && <span className="text-danger">*</span>}
                  </label>
                  <input
                    {...formik.getFieldProps("password_confirmation")}
                    type="password"
                    name="password_confirmation"
                    id="password_confirmation"
                    className="form-control"
                    required={userId ? false : true}
                  />
                </div>
              </div>
            </div>
            <hr className="my-4"></hr>
            <div className="row mt-3 gap-3">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="profile_id">
                    Perfil do usuário <span className="text-danger">*</span>
                  </label>
                  <select
                    {...formik.getFieldProps("profile_id")}
                    required
                    name="profile_id"
                    id="profile_id"
                    className="form-select"
                  >
                    <option value="">Selecione um perfil</option>
                    {profiles &&
                      profiles.map((profile, index) => (
                        <option key={index} value={profile.id}>
                          {profile.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="areas_ids">
                    Áreas
                  </label>
                  <Select
                    isMulti
                    isClearable
                    placeholder="Selecione uma ou mais áreas"
                    name="areas_ids"
                    id="areas_ids"
                    value={initialSelectAreas}
                    options={[{label: "Selecionar todas", value: "*"}, ...selectAreas]}
                    onChange={(option: MultiValue<SelectType>, event: ActionMeta<SelectType>) => {
                      updateAreasField(option, event)
                    }}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="areas_ids">
                    Células
                  </label>
                  <Select
                    isMulti
                    isClearable
                    placeholder="Selecione uma ou mais células"
                    name="cells_ids"
                    id="cells_ids"
                    value={initialSelectCells}
                    options={[{label: "Selecionar todas", value: "*"}, ...selectCells]}
                    onChange={(option: MultiValue<SelectType>, event: ActionMeta<SelectType>) => updateCellsField(option, event)}
                  />
                </div>
              </div>
            </div>
            <div className="row  mt-5">
              <div className="col-12">
                <Link to={"/users"} className="btn btn-danger">
                  <i className="bi bi-arrow-left"></i> Voltar
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary ms-3"
                  disabled={
                    formik.isSubmitting || !formik.isValid || !formik.touched
                  }
                >
                  <span>Salvar</span>
                  {formik.isSubmitting && (
                    <div
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { UsersForm };
